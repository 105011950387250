import { useGridParams } from "@/utils/useGridParams";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { prepareParams } from "@/utils";
import { Button, PageHeader } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { TableWithPaginationAndSorting } from "@/components/common";
import { columns } from "./columns";
import { useExportSchedules } from "@/api/export-schedules/hooks";

export const ExportScheduleList = () => {
  const { filters, pagination, sort } = useGridParams();

  const history = useHistory();
  const handleEdit = useCallback(({ id }) => history.push(`/export-schedules/${id}`), [history]);

  const { data, isLoading } = useExportSchedules(prepareParams(filters, pagination, sort));

  const handleCreate = () => {
    history.push(`/export-schedules/new`);
  };

  return (
    <>
      <PageHeader
        style={{ marginBottom: 16 }}
        title={<h1 style={{ margin: 0 }}>Export Schedules</h1>}
        extra={[
          <Button key="new" type="primary" icon={<Icon component={Plus} />} onClick={handleCreate}>
            New Export Schedule
          </Button>,
        ]}
      />
      <TableWithPaginationAndSorting
        rowKey="id"
        onRowClick={handleEdit}
        columns={columns}
        data={data}
        loading={isLoading}
        defaultSortColumn="feed.name"
      />
    </>
  );
};
