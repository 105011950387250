import * as Salesforce from "@/types/salesforce";
import { useSalesforceObject } from "@/api/salesforce-objects/hooks";
import { useMemo } from "react";
import type { SalesforceObject, SalesforceObjectField } from "@/api/salesforce-objects/types";

const isContactField = (field: SalesforceObjectField) =>
  field.type === "email" ||
  (field.type === "reference" &&
    [Salesforce.SalesforceObject.ConcordBucks, Salesforce.SalesforceObject.Contact].includes(
      // @ts-expect-error
      field.referenceToName
    ));

const getContactFields = (obj: SalesforceObject, parents: SalesforceObjectField[] = [], nesting = 1) =>
  obj?.fields?.flatMap((field) => {
    if (isContactField(field)) {
      return {
        ...field,
        name: [...parents.map((x) => x.name), field.name].join("."),
        label: [...parents.map((x) => x.label), field.label].join(" / "),
      };
    }
    if (nesting > 0 && field.type === "reference" && field.referenceTo) {
      if (field.referenceToName === "User" || field.referenceToName === "Group") {
        return [];
      }
      return getContactFields(field.referenceTo, [...parents, field], nesting - 1);
    }
    return [];
  }) ?? [];

const useSalesforceObjectContactFields = (objectName: string) => {
  const { obj, isLoading } = useSalesforceObject(objectName);
  const fields = useMemo(() => getContactFields(obj), [obj]);
  return { fields, isLoading };
};

export { useSalesforceObjectContactFields };
