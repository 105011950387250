import { useDictionaries } from "@/utils/useDictionaries";
import { OperationTag } from "../../common/OperationTag/OperationTag";
import { ProcessingStatusTag } from "../../common/ProcessingStatusTag";
import { Truncated } from "../Truncated";
import { DATETIME_FORMATTER } from "@/utils";
import { TagFilter } from "@/components/common/TagFilter";

export const TenantCell = ({ tenantCode }) => {
  const { dictionaries } = useDictionaries();
  const { tenants } = dictionaries.openplay;
  const tenant = tenants.find((x) => x.code === tenantCode);
  return tenant ? tenant.name : "-";
};

export const operationTypeNameLookup = {
  ongoing: "Ongoing",
  "manual-export": "Manual Export",
  resend: "Resend",
  "delayed-export": "Delayed Export",
};

export const ActivityTableColumns = {
  Operation: {
    title: "Operation",
    dataIndex: "operation",
    key: "operation",
    sorter: true,
    render: (_, entry) => (
      <TagFilter filters={{ operation: entry.operation }}>
        <OperationTag operation={entry.operation} showLabel />
      </TagFilter>
    ),
  },
  Status: {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: true,
    render: (_, entry) =>
      entry.status ? (
        <TagFilter filters={{ "statuses[]": [entry.status] }}>
          <ProcessingStatusTag status={entry.status} />
        </TagFilter>
      ) : (
        "-"
      ),
  },
  OperationType: {
    title: "Operation Type",
    dataIndex: "operationType",
    key: "operationType",
    sorter: true,
    render: (value) => operationTypeNameLookup[value] || "-",
  },
  StartedAt: {
    title: "Started At",
    dataIndex: "startedAt",
    key: "startedAt",
    sorter: true,
    render: (_, entry) => DATETIME_FORMATTER.format(new Date(entry.startedAt)),
  },
  UPC: {
    title: "UPC",
    dataIndex: "upc",
    key: "upc",
    sorter: true,
  },
  ProjectNumber: {
    title: "Project Number",
    dataIndex: "projectNumber",
    key: "projectNumber",
    sorter: true,
  },
  Tenant: {
    title: "Tenant",
    dataIndex: "tenantCode",
    key: "tenantCode",
    sorter: true,
    render: (value) => <TenantCell tenantCode={value} />,
  },
  ReleaseArtist: {
    title: "Artist",
    dataIndex: ["release", "artist_display_name"],
    key: "release.artist_display_name",
    sorter: true,
    render: (value) => <Truncated text={value} />,
  },
  ReleaseTitle: {
    title: "Title",
    dataIndex: ["release", "display_title"],
    key: "release.display_title",
    sorter: true,
    render: (value) => <Truncated text={value} />,
  },
  ReleaseLabel: {
    title: "Label",
    dataIndex: ["release", "label", "name"],
    key: "release.label.name",
    sorter: true,
    render: (value) => <Truncated text={value} />,
  },
  ProjectTitle: {
    title: "Title",
    dataIndex: ["project", "title"],
    key: "project.title",
    sorter: true,
    render: (value) => <Truncated text={value} />,
  },
  ProjectLabelGroup: {
    title: "Label Group",
    dataIndex: ["project", "label_group_name"],
    key: "project.label_group_name",
    sorter: true,
    render: (value) => <Truncated text={value} />,
  },
  Feed: {
    title: "Feed",
    dataIndex: ["feed", "name"],
    key: "feed.name",
    sorter: true,
    render: (value) => <Truncated text={value} />,
  },
};
