import { useState } from "react";
import { Button, Modal, Select } from "antd";
import { useBoolean } from "ahooks";
import { Close, DropdownArrow } from "../Icons";
import Icon from "@ant-design/icons";
import { Tooltip } from "@/components/common/Tooltip";
import "./CopyFromTemplate.less";
import type { Template, TemplateFilterDto } from "@/api/templates/types";
import type { ButtonType } from "antd/lib/button/button";
import { useTemplates } from "@/api/templates/hooks";

type Props = {
  disabled?: boolean;
  onCopy: (template: Template) => void;
  buttonType?: ButtonType;
  filter: TemplateFilterDto;
};

export const CopyFromTemplate = ({ disabled, filter, onCopy, buttonType = "default" }: Props) => {
  const [isFormVisible, { setTrue: showForm, setFalse: hideForm }] = useBoolean(false);
  const [templateId, setTemplateId] = useState();
  const { isLoading, data: paginatedTemplates, mutate } = useTemplates(filter);

  const hasTemplates = paginatedTemplates.data.length > 0;

  const options = paginatedTemplates.data.map((d) => ({ value: d.id, label: d.name }));

  const handleCopy = () => {
    onCopy(paginatedTemplates.data.find((template) => template.id === templateId));
    hideForm();
    setTemplateId(null);
  };

  const handleOpenForm = async () => {
    await mutate();
    showForm();
  };

  return (
    <>
      <Tooltip title={hasTemplates ? null : "No templates found with given parameters"}>
        <Button onClick={handleOpenForm} disabled={disabled || !hasTemplates} type={buttonType}>
          Copy from template
        </Button>
      </Tooltip>
      <Modal
        open={isFormVisible}
        title="Copy from a custom template?"
        onCancel={hideForm}
        className="save-as-popup"
        footer={
          <>
            <Button onClick={hideForm}>Cancel</Button>
            <Button type="primary" disabled={!templateId} onClick={handleCopy}>
              Copy
            </Button>
          </>
        }
        closeIcon={<Close />}
      >
        <Select
          placeholder="Select template"
          className="copy-from-template__input"
          value={templateId}
          onChange={setTemplateId}
          options={options}
          loading={isLoading}
          suffixIcon={<Icon component={DropdownArrow} />}
        />
      </Modal>
    </>
  );
};
