import { useGridParams } from "@/utils/useGridParams";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { prepareParams } from "@/utils";
import { Button, PageHeader } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { TableWithPaginationAndSorting } from "@/components/common";
import { columns } from "./columns";
import { useFlows } from "@/api/flows/hooks";

export const FlowsList = () => {
  const { filters, pagination, sort } = useGridParams();

  const history = useHistory();
  const handleEdit = useCallback(({ id }) => history.push(`/flows/${id}`), [history]);

  const { data, isLoading } = useFlows(prepareParams(filters, pagination, sort));

  const handleCreate = () => {
    history.push(`/flows/new`);
  };

  return (
    <>
      <PageHeader
        style={{ marginBottom: 16 }}
        title={<h1 style={{ margin: 0 }}>Flows</h1>}
        extra={
          <Button type="primary" icon={<Icon component={Plus} />} onClick={handleCreate}>
            New Flow
          </Button>
        }
      />
      <TableWithPaginationAndSorting
        rowKey="id"
        onRowClick={handleEdit}
        columns={columns}
        data={data}
        loading={isLoading}
      />
    </>
  );
};
