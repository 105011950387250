import { useOpenplayObject } from "@/api/openplay-objects/hooks";
import type { OpenplayObjectType } from "@/types/common";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { sortCascaderSearchResults } from "@/utils/autocomplete";
import { Cascader } from "antd";
import { openplayPathsToOptions } from "@/components/common/ObjectPathPicker/utils";
import { useMemo } from "react";

type Props = {
  value?: string[];
  onChange?: (value: string[]) => void;
  objectName: OpenplayObjectType;
};

export const OpenplayObjectPathPicker = ({ value, onChange, objectName }: Props) => {
  const { data: openplayObject, isLoading } = useOpenplayObject(objectName);

  const options = useMemo(() => openplayPathsToOptions(openplayObject?.root?.children ?? []), [
    openplayObject?.root?.children,
  ]);

  return (
    <Cascader
      value={value}
      onChange={onChange}
      placeholder="Source Field"
      options={options}
      suffixIcon={<Icon component={DropdownArrow} />}
      disabled={isLoading || options.length === 0}
      showSearch={{ limit: 200, sort: sortCascaderSearchResults }}
      placement="bottomLeft"
    />
  );
};
