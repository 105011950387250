import { useDownload } from "@/utils/useDownload";
import { downloadSpreadsheet } from "@/api/reports";
import { DownloadOutlined } from "@ant-design/icons";
import type { FormInstance, MenuProps } from "antd";
import { Button, Dropdown, Form } from "antd";
import { Tooltip } from "@/components/common/Tooltip";
import type { SalesforceQuery, Variation } from "@/api/reports/types";

type Props = {
  form: FormInstance;
};

export const DownloadSpreadsheetButton = ({ form }: Props) => {
  const reportId = Form.useWatch<string>("id", form);
  const queries = Form.useWatch<SalesforceQuery[]>("queries", form) ?? [];
  const variations = Form.useWatch<Variation[]>("variations", form) ?? [];

  const { start: download, loading: downloading } = useDownload(downloadSpreadsheet, {
    message: "The spreadsheet is being generated. Download will start automatically",
    noContentMessage: "The are no records to include into the file",
  });

  const isDisabled = queries.some((query) => !query.from);

  if (variations.length === 0) {
    return (
      <Button
        icon={<DownloadOutlined />}
        onClick={() => download(reportId)}
        loading={downloading}
        disabled={isDisabled}
      >
        Download .xlsx
      </Button>
    );
  }

  const items: MenuProps["items"] = variations.map((variation, variationIndex) => {
    const label = `Variation #${variationIndex + 1}`;
    return {
      key: variationIndex,
      label,
      disabled: !variation?.query.where,
      onClick: () => download(reportId, variationIndex),
    };
  });

  return (
    <Tooltip title={isDisabled ? "Please, configure the query first" : null}>
      <Dropdown menu={{ items }}>
        <Button icon={<DownloadOutlined />} loading={downloading} disabled={isDisabled}>
          Download .xlsx
        </Button>
      </Dropdown>
    </Tooltip>
  );
};
