import type { ContactField } from "@/api/email-alerts/types";
import { Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import type { SalesforceObjectField } from "@/api/salesforce-objects/types";
import { useSalesforceObjectContactFields } from "@/utils/useSalesforceObjectContactFields";

export type ContactFieldPickerProps = {
  value?: ContactField;
  onChange?: (value: ContactField) => void;
  sourceObject: string;
};

export const ContactFieldPicker = ({ value, onChange, sourceObject }: ContactFieldPickerProps) => {
  const { fields, isLoading } = useSalesforceObjectContactFields(sourceObject);

  const handleChange = (_: string, field: SalesforceObjectField) => {
    onChange?.({ type: field.type, name: field.name });
  };

  return (
    <Select
      value={value?.name ?? null}
      onChange={handleChange}
      options={fields.map(({ name, label, type, referenceToName }) => ({
        name,
        label,
        type: type === "reference" ? referenceToName : type,
      }))}
      placeholder="Select the source field"
      suffixIcon={<Icon component={DropdownArrow} />}
      fieldNames={{ value: "name", label: "label" }}
      loading={isLoading}
      notFoundContent="Source object does not have any contact fields"
    />
  );
};
