import useSWR from "swr";
import { fetchUsers, getPermissionSubjects, getUser, saveUser } from "@/api/users/index";
import type { UserFilters } from "@/api/users/types";
import useSWRMutation from "swr/mutation";
import useSWRImmutable from "swr/immutable";

export const useUser = (id: string) => useSWR(["users", id], ([_, id]) => getUser(id));

export const useUsers = (filters: UserFilters = {}) =>
  useSWR(["users", filters], ([_, filters]) => fetchUsers(filters));

export const useUserMutation = (id: string) =>
  useSWRMutation(id ? ["users", id] : "users", (_, { arg: dto }: { arg }) => saveUser(dto));

export const usePermissionSubjects = () => useSWRImmutable("permission-subjects", getPermissionSubjects);
