import React, { useCallback, useMemo } from "react";
import TemplatesTable from "./TemplatesTable";
import FilterableContent from "../../Layout/FilterableContent";
import { filtersConfig as filtersFormConfig } from "./config";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import Icon from "@ant-design/icons";
import { Plus } from "../../common/Icons";

export function TemplatesList() {
  const history = useHistory();
  const handleNew = useCallback(() => history.push("/templates/new"), [history]);
  const button = useMemo(
    () => [
      <Button
        type="primary"
        htmlType="submit"
        style={{ marginLeft: "auto" }}
        key="new-partner"
        onClick={handleNew}
        icon={<Icon component={Plus} />}
      >
        New Template
      </Button>,
    ],
    [handleNew]
  );
  return (
    <>
      <h1>Templates</h1>
      <FilterableContent
        formConfig={filtersFormConfig}
        filterName="release-delivery-filter"
        isFiltersConfigurable={false}
        buttons={button}
      >
        <TemplatesTable />
      </FilterableContent>
    </>
  );
}
