import type { PaginatedResponse, Pagination } from "@/api/types";
import { api } from "@/api/core";
import type {
  CreateFlowDto,
  Flow,
  FlowEvent,
  ImportPlatformEventParams,
  Script,
  ScriptDescriptionUpdateDto,
  ScriptFilters,
  UpdateFlowDto,
} from "./types";

export const url = "/api/flows";

export const fetchFlows = (params: Pagination) => api.get<any, PaginatedResponse<Flow>>(url, { params });

export const fetchFlow = (id: string) => api.get<any, Flow>(`${url}/${id}`);

export const createFlow = (fields: CreateFlowDto) => api.post<any, Flow>(url, fields);

export const updateFlow = (fields: UpdateFlowDto) => api.put<any, Flow>(url, fields);

export const deleteFlow = (id: Flow["id"]) => api.delete<any, void>(`${url}/${id}`);

export const saveFlow = (fields: CreateFlowDto | UpdateFlowDto) =>
  "id" in fields ? updateFlow(fields) : createFlow(fields);

export const findFlowByName = (name: string) => api.get<any, Flow>(`${url}/find`, { params: { name } });

export const fetchAvailableFlowEvents = () => api.get<any, FlowEvent[]>(`${url}/events`);

export const deleteFlowEvent = (name: string) => api.delete<any, void>(`${url}/events/${name}`);

export const pullChangeEvents = () => api.post<any, void>(`${url}/events/cdc`);

export const pullCustomEvent = (params: ImportPlatformEventParams) =>
  api.post<any, void>(`${url}/events/platform`, params);

export const fetchScript = (key: string) => api.get<any, Script>(`/api/scripts/${key}`);

export const fetchScripts = (params: ScriptFilters = {}) =>
  api.get<any, Script[]>(`/api/scripts`, { params });

export const updateScript = (params: ScriptDescriptionUpdateDto) =>
  api.put<any, void>(`/api/scripts`, params);
