import { api } from "./core";
import { LOG_OUT_URL, RESET_PASSWORD_URL, SIGN_IN_URL } from "./urls";

/**
 *
 * @param {string} email
 * @param {string} password
 * @returns {Promise<Object>}
 */
export async function login(email, password) {
  return api.post(SIGN_IN_URL, {
    email,
    password,
  });
}

/**
 *
 * @param {string} email
 * @returns {Promise<Object>}
 */
export async function resetPassword(email) {
  return api.post(RESET_PASSWORD_URL, { email });
}

export async function logout() {
  return api.post(LOG_OUT_URL);
}
