import React, { useMemo } from "react";
import { Col, Form, Input, Row } from "antd";
import { getConfig } from "./config";
import { useDictionaries } from "../../../utils/useDictionaries";
import { Box } from "../Box/Box";
import Icon from "@ant-design/icons";
import { Info } from "../Icons";
import { QueryBuilder } from "../QueryBuilder/QueryBuilder";

export const ReleaseFiltersField = ({ tenantCode, value, onChange, onBlur }) => {
  const { dictionaries } = useDictionaries();
  const config = useMemo(() => getConfig(tenantCode, dictionaries), [dictionaries, tenantCode]);
  return <QueryBuilder config={config} value={value} onChange={onChange} onBlur={onBlur} />;
};

const ReleaseFilters = ({ disabled, rules }) => {
  const tenantCode = Form.useWatch("tenantCode");
  return (
    <Box readOnly={disabled}>
      <Row align="top">
        <Col span={14} className="filter-heading">
          <Icon component={Info} className="filter-heading__icon" />
          <div>
            <div>Create the logic by adding filters and groups and changing the operators.</div>
            <div>
              For example, you can create "1 AND 2 OR 3" or "1 AND (2 OR 3)" to get the right results.
            </div>
          </div>
        </Col>
      </Row>
      <Row align="top">
        <Col span={24}>
          <Form.Item hidden name={["filter", "id"]}>
            <Input />
          </Form.Item>
          <Form.Item name={["filter", "configuration"]} className="filter-row" rules={rules}>
            <ReleaseFiltersField tenantCode={tenantCode} />
          </Form.Item>
        </Col>
      </Row>
    </Box>
  );
};

export default ReleaseFilters;
