import { Form, Input, PageHeader, Tabs } from "antd";
import { useCallback, useMemo, useState } from "react";
import { FeedsList } from "../../Feeds";
import { useHistory, useParams } from "react-router-dom";
import { openFormNotification } from "../../common/CommonNotification";
import { showNavigationConfirmation, useNavigationConfirm } from "@/utils/useNavigationConfirm";
import PartnerDetailsForm from "./PartnerDetailsForm/PartnerDetailsForm";
import { useBoolean } from "ahooks";
import { mapIds, PartnerTab, useIsAllowedForRoles, UserRole } from "@/utils";
import { AddFeedButton } from "./AddFeedButton";
import * as R from "ramda";
import { FormActions } from "../../common/FormActions";
import { TabErrorIcon } from "../../common/TabErrorIcon/TabErrorIcon";
import { PartnerActions } from "./PartnerActions";
import { usePartnerMutation } from "@/api/partners/hooks";

const { TabPane } = Tabs;

const summaryFields = [
  "name",
  "ddexPartyId",
  "primaryContactName",
  "primaryContactEmail",
  "address",
  "primaryContactPhone",
  "users",
  "isActive",
  "deactivationReason",
  "onHoldDays",
  "usePreorderDate",
];

export const PartnerForm = ({ defaultValues = {}, isEditingByDefault = false }) => {
  const titleText = defaultValues.name || "New Partner";
  const [form] = Form.useForm();
  const { activeTab } = useParams();

  const canAddFeeds = useIsAllowedForRoles(UserRole.Admin);

  const { allowNavigation, preventNavigation, isNavigateAllowed } = useNavigationConfirm();
  const history = useHistory();

  const [isEditing, { setFalse: disableEditing, setTrue: enableEditing }] = useBoolean(isEditingByDefault);

  const initialData = useMemo(() => {
    return {
      ...defaultValues,
      users: mapIds(defaultValues.users ?? []),
    };
  }, [defaultValues]);

  const handleCancel = useCallback(() => {
    allowNavigation();
    if (defaultValues.id) {
      form.resetFields();
      disableEditing();
    } else {
      history.push(`/partners`);
    }
  }, [defaultValues, history, form, disableEditing, allowNavigation]);

  const onTabChanged = useCallback(
    (newTab) => {
      const to = `/partners/${defaultValues.id || "new"}/${newTab}`;
      if (newTab === PartnerTab.Feeds) {
        disableEditing();
        if (isNavigateAllowed) {
          history.replace(to);
        } else {
          showNavigationConfirmation(() => {
            form.resetFields();
            allowNavigation();
            history.replace(to);
          });
        }
      } else {
        history.replace(to);
      }
    },
    [defaultValues.id, disableEditing, isNavigateAllowed, history, allowNavigation, form]
  );

  const handleValueChange = useCallback(() => {
    if (isEditing) {
      preventNavigation();
    }
  }, [isEditing, preventNavigation]);

  const { isMutating: saving, trigger: save } = usePartnerMutation(initialData.id);

  const handleSave = async (values) => {
    const dto = { ...values };

    try {
      const { id } = await save(dto);
      openFormNotification(dto.name);
      allowNavigation();
      if (!initialData.id) {
        history.push(`/partners/${id}`);
      }
      disableEditing();
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  const showPartnerActions = activeTab === PartnerTab.Summary;
  const showAddFeedButton = activeTab === PartnerTab.Feeds && canAddFeeds && !!defaultValues.id;

  const [fieldsWithErrors, setFieldsWithErrors] = useState([]);

  const handleErrors = useCallback(({ errorFields }) => {
    const names = R.compose(
      R.flatten,
      R.map((error) => error.name)
    )(errorFields);
    setFieldsWithErrors(names);
  }, []);

  const isSummaryTabError = R.intersection(summaryFields, fieldsWithErrors).length > 0;

  return (
    <>
      <PageHeader
        title={titleText}
        extra={
          <>
            {showPartnerActions && (
              <>
                {!isEditing && <PartnerActions partner={initialData} />}
                <FormActions
                  isEditing={isEditing}
                  onEdit={enableEditing}
                  onCancel={handleCancel}
                  onSave={form.submit}
                  isSaving={saving}
                />
              </>
            )}
            {showAddFeedButton && <AddFeedButton partnerId={defaultValues.id} />}
          </>
        }
      />
      <Form
        form={form}
        onFinish={handleSave}
        onFinishFailed={handleErrors}
        initialValues={initialData}
        onValuesChange={handleValueChange}
        colon={false}
        labelAlign="left"
        wrapperCol={{ span: 15, offset: 1 }}
        labelCol={{ span: 8 }}
        validateMessages={{
          required: "Field is mandatory",
        }}
      >
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Tabs activeKey={activeTab} onChange={onTabChanged}>
          <TabPane
            tab={
              <>
                <span>Summary</span>
                {isSummaryTabError && <TabErrorIcon />}
              </>
            }
            key={PartnerTab.Summary}
            forceRender
          >
            <PartnerDetailsForm disabled={!isEditing} initialUsers={defaultValues.users ?? []} />
          </TabPane>
          {defaultValues.id && (
            <TabPane tab="Feeds" key={PartnerTab.Feeds}>
              <FeedsList partnerId={defaultValues.id} />
            </TabPane>
          )}
        </Tabs>
      </Form>
    </>
  );
};
