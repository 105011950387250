import React, { useMemo } from "react";
import { Col, Form, Row, Select } from "antd";
import ReleaseFilters from "../../common/ReleaseFilters/ReleaseFilters";
import { useDictionaries } from "../../../utils/useDictionaries";
import { hasLabelInFilters } from "../../common/ReleaseFilters/utils";
import { dictionaryToOptions } from "../../../utils";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "../../common/Icons";
import { Box } from "../../common/Box/Box";

export const FiltersTemplateForm = ({ disabled }) => {
  const { dictionaries } = useDictionaries();

  const filter = Form.useWatch(["filter", "configuration"]);

  const isLabelSetOnFilter = useMemo(() => {
    if (!filter) {
      return false;
    }
    return hasLabelInFilters(filter);
  }, [filter]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Box readOnly={disabled}>
          <Row>
            <Col span={12}>
              <Form.Item
                name="tenantCode"
                label="Tenant Name"
                tooltip={
                  isLabelSetOnFilter &&
                  "Please remove filter rules containing 'label' to enable tenant code selection"
                }
              >
                <Select
                  placeholder="Select Tenant"
                  options={dictionaryToOptions(dictionaries?.openplay.tenants ?? [])}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showArrow={false}
                  showSearch
                  allowClear={true}
                  optionFilterProp="label"
                  disabled={isLabelSetOnFilter}
                  suffixIcon={<Icon component={DropdownArrow} />}
                />
              </Form.Item>
            </Col>
          </Row>
        </Box>
      </Col>
      <Col span={24}>
        <ReleaseFilters
          disabled={disabled}
          rules={[{ required: true, message: "At least one rule should be configured" }]}
        />
      </Col>
    </Row>
  );
};
