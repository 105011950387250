import { api } from "../core";
import type { CreateReportDto, Report, ReportFilters, UpdateReportDto } from "./types";
import type { PaginatedResponse } from "../types";
import { stripUndefined } from "@/utils";

export const url = "/api/reports";

export const fetchReport = (id: string) => api.get<any, Report>(`${url}/${id}`);

export const fetchReports = (params: ReportFilters) =>
  api.get<any, PaginatedResponse<Report>>(url, { params });

export const createReport = (fields: CreateReportDto) => api.post<any, Report>(url, fields);

export const updateReport = (fields: UpdateReportDto) => api.put<any, Report>(url, fields);

export const deleteReport = (id: string) => api.delete<any, void>(`${url}/${id}`);

export const saveReport = (fields: CreateReportDto | UpdateReportDto) => {
  const cleanedUpFields = stripUndefined(fields);
  return "id" in cleanedUpFields ? updateReport(cleanedUpFields) : createReport(cleanedUpFields);
};

export const findReportByName = (name: string) => api.get<any, Report>(`${url}/find`, { params: { name } });

export const importReport = (fd: FormData) => api.post<any, Report>(`${url}/import`, fd);

export const exportReport = (id: string) =>
  api.post<void, Blob>(`${url}/export`, { id }, { responseType: "blob" });

export const downloadSpreadsheet = (id: string, variationIndex?: number) =>
  api.post<void, Blob>(`${url}/${id}/download-spreadsheet`, { variationIndex }, { responseType: "blob" });

export const duplicateReport = (id: string, name: string) =>
  api.post<void, Report>(`${url}/${id}/duplicate`, { name });