import { useMemo } from "react";
import { CustomAntdConfig } from "../../QueryBuilder/config";
import type { Config } from "@react-awesome-query-builder/ui";
import type { SalesforceObject } from "@/api/salesforce-objects/types";
import { useSalesforceObject } from "@/api/salesforce-objects/hooks";
import { getSalesforceObjectQueryBuilderFields } from "@/utils/query-builder";

const InitialConfig = CustomAntdConfig;

export const getConfig = (objectWithFields: SalesforceObject): Config => ({
  ...InitialConfig,
  fields: getSalesforceObjectQueryBuilderFields(objectWithFields, 2),
});

export const useSalesforceQueryBuilderConfig = (objectName: string): { config: Config; loading: boolean } => {
  const { obj: objectWithFields, isLoading } = useSalesforceObject(objectName);
  const config = useMemo(() => (objectWithFields ? getConfig(objectWithFields) : null), [objectWithFields]);
  return { config, loading: isLoading };
};
