import React from "react";
import { FeedMappingTemplateSelector } from "./FeedMappingTemplateSelector";
import { Col, Form, Input, Row, Spin } from "antd";
import * as PropTypes from "prop-types";
import ExtraMapping from "../../../common/CommonMappings/ExtraMappings";
import { useDefaultMappings } from "../../../common/CommonMappings/useMappings";
import { DefaultMappings } from "../../../common/CommonMappings/DefaultMappings";
import { SaveAsMappingTemplate } from "../SaveAsTemplate/SaveAsMappingTemplate";
import { useMappingTemplate } from "../../utils";
import { requiredIfNotDraft } from "../../../../utils/validation";

const MappingsForm = ({ disabled = false }) => {
  const { inputFormat, outputFormat, isSelected: isTemplateSelected } = useMappingTemplate();
  const { mappings, loading } = useDefaultMappings(inputFormat, outputFormat);
  return (
    <>
      <Form.Item name={["mapping", "id"]} noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={["mapping", "inputFormat"]} noStyle rules={[requiredIfNotDraft]}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={["mapping", "outputFormat"]} noStyle rules={[requiredIfNotDraft]}>
        <Input type="hidden" />
      </Form.Item>
      {isTemplateSelected ? (
        <Spin spinning={loading}>
          <Row align="end" style={{ marginBottom: 16 }}>
            <SaveAsMappingTemplate disabled={disabled} />
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <ExtraMapping fieldName="mapping" disabled={disabled} defaultMappings={mappings} />
            </Col>
            {mappings?.length > 0 && (
              <Col span={24}>
                <DefaultMappings data={mappings} />
              </Col>
            )}
          </Row>
        </Spin>
      ) : (
        <FeedMappingTemplateSelector disabled={disabled} />
      )}
    </>
  );
};

MappingsForm.propTypes = {
  disabled: PropTypes.bool,
};

export default MappingsForm;
