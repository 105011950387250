import { Button, PageHeader } from "antd";
import {
  getStatusLabel,
  getUserRoleLabel,
  prepareParams,
  Status as StatusCode,
  useAuth,
  UserRole,
} from "@/utils";
import { TableWithPaginationAndSorting } from "../../common";
import { columns } from "./columns";
import Icon from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "./Users.less";
import { Plus } from "../../common/Icons";
import { useGridParams } from "@/utils/useGridParams";
import { useUsers } from "@/api/users/hooks";
import FilterableContent from "@/components/Layout/FilterableContent";
import type { Filter } from "@/components/common/FilterInput/FilterInput";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { toOptions } from "@/utils/toOptions";
import { fetchUsers } from "@/api/users";
import { useMemo } from "react";

export const UsersList = () => {
  const { pagination, sort, filters } = useGridParams();
  const history = useHistory();

  const handleCreate = () => history.push("/users/new");
  const handleEdit = ({ id }) => history.push(`/users/${id}`);

  const { data, isLoading } = useUsers(prepareParams(filters, pagination, sort));

  const { user } = useAuth();
  const role = user?.role as UserRole;

  const filterConfig: Filter[] = useMemo(
    () => [
      {
        name: "nameContains",
        label: "Name",
        type: FilterType.Autocomplete,
        getSuggestions: async (query) => {
          const response = await fetchUsers({ nameContains: query });
          return response.data.map((user) => user.name);
        },
      },
      {
        name: "status",
        label: "status",
        type: FilterType.Radio,
        options: toOptions([StatusCode.Active, StatusCode.Inactive].map(String), getStatusLabel),
      },
      {
        name: "role",
        label: "role",
        type: FilterType.Select,
        options: toOptions(
          [
            role >= UserRole.Superadmin && UserRole.Superadmin,
            role >= UserRole.Admin && UserRole.Admin,
            UserRole.Operational,
          ]
            .filter(Boolean)
            .map(String),
          getUserRoleLabel
        ),
      },
    ],
    [role]
  );

  return (
    <>
      <PageHeader
        backIcon={false}
        className="site-page-header"
        onBack={() => null}
        title={<h1>Users</h1>}
        extra={[
          <Button key="new-User" type="primary" onClick={handleCreate} icon={<Icon component={Plus} />}>
            New User
          </Button>,
        ]}
      />
      <FilterableContent formConfig={filterConfig} filterName="user-filter" isFiltersConfigurable={false}>
        <TableWithPaginationAndSorting
          rowKey="id"
          onRowClick={handleEdit}
          columns={columns}
          data={data}
          loading={isLoading}
          className={"users-table-wrapper"}
        />
      </FilterableContent>
    </>
  );
};
