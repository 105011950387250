import { api } from "../core";
import { TEMPLATE_URL, TEMPLATE_FIND_URL, TEMPLATES_URL } from "../urls";
import { stripUndefined } from "@/utils";
import type {
  CreateTemplateDto,
  Template,
  TemplateFilterDto,
  UpdateTemplateDto,
} from "@/api/templates/types";
import type { PaginatedResponse } from "@/api/types";

export const createTemplate = async (dto: CreateTemplateDto) => api.post<any, Template>(TEMPLATES_URL, dto);

export const updateTemplate = async (dto: UpdateTemplateDto) => api.put<any, Template>(TEMPLATES_URL, dto);

export const saveTemplate = (fields: CreateTemplateDto | UpdateTemplateDto) => {
  const cleanedUpFields = stripUndefined(fields);
  return "id" in cleanedUpFields ? updateTemplate(cleanedUpFields) : createTemplate(cleanedUpFields);
};

export const fetchTemplate = async (id: string) => api.get<any, Template>(TEMPLATE_URL(id));

export const saveAsTemplate = async (dto: CreateTemplateDto) => createTemplate(dto);

export const deleteTemplate = async (id: string) => api.delete<any, void>(TEMPLATE_URL(id));

export const fetchTemplates = async (params: TemplateFilterDto) =>
  api.get<any, PaginatedResponse<Template>>(TEMPLATES_URL, {
    params,
  });

export const findTemplateByName = async (name: string) =>
  await api.get(TEMPLATE_FIND_URL, { params: { name } });
