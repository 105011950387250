import React, { useCallback, useContext } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useAuth } from "./useAuth";
import { notification } from "antd";
import { useIsAllowedForRoles } from "./useIsAdmin";
import PropTypes from "prop-types";
import { LayoutContext } from "@/contexts/layout";

export function PrivateRoute({
  allowedFor = [],
  secondaryScreen = false,
  fullWidth = false,
  children,
  ...rest
}) {
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const isAllowed = useIsAllowedForRoles(allowedFor);
  const { setSecondary, setPrimary, setFullWidth } = useContext(LayoutContext);

  const nestedContent = useCallback(
    ({ location }) => {
      if (!isAuthenticated) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      } else if (!isAllowed) {
        notification.error({
          message: "Permission denied",
          placement: "bottomRight",
        });
        history.replace("/");
      } else {
        if (secondaryScreen) {
          setSecondary();
        } else {
          setPrimary();
        }
        setFullWidth(fullWidth);
        return children;
      }
    },
    [
      secondaryScreen,
      setPrimary,
      setSecondary,
      isAllowed,
      isAuthenticated,
      history,
      children,
      setFullWidth,
      fullWidth,
    ]
  );

  return <Route {...rest} render={nestedContent} />;
}

PrivateRoute.propTypes = {
  adminOnly: PropTypes.bool,
  secondaryScreen: PropTypes.bool,
  children: PropTypes.node,
};
