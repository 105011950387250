import { Layout, Menu } from "antd";
import { useCallback, useMemo } from "react";
import Icon from "@ant-design/icons";
import {
  Cloud,
  Dashboard,
  ExportSchedule,
  Flow,
  Gears,
  Link,
  Mail,
  Movement,
  OpenPlay,
  Salesforce,
  Users,
  Wrench,
} from "../../common/Icons";
import { checkRoles, isNotEmpty, useAuth, UserRole } from "@/utils";
import * as R from "ramda";
import { useLocation } from "react-router-dom";
import { LinkWithConfirmation } from "@/utils/useNavigationConfirm";
import "./Sidebar.less";
import { Logo, LogoColor, LogoSize } from "../Logo";
import { defaultStatusesQuery } from "@/components/Activity/utils";

const { Sider } = Layout;

const menu = [
  {
    key: "releases",
    route: "/releases",
    title: "Releases",
    roles: [],
    icon: Dashboard,
  },
  {
    key: "partners",
    route: "/partners",
    title: "Partners",
    roles: [],
    icon: Cloud,
  },
  {
    key: "export-schedules",
    route: "/export-schedules",
    title: "Export Schedules",
    roles: [],
    icon: ExportSchedule,
  },
  {
    key: "users",
    route: "/users",
    title: "Users",
    roles: [UserRole.Admin],
    icon: Users,
  },
  {
    key: "templates",
    route: "/templates",
    title: "Templates",
    roles: [],
    icon: Wrench,
  },
  {
    key: "activity",
    route: {
      pathname: "/activity/release",
      search: defaultStatusesQuery,
    },
    title: "Activity",
    roles: [],
    icon: Movement,
  },
  {
    key: "_salesforce",
    title: "Salesforce",
    icon: Salesforce,
    children: [{ key: "salesforce-objects", route: "/salesforce-objects", title: "Objects" }],
  },
  {
    key: "_openplay",
    title: "OpenPlay",
    icon: OpenPlay,
    roles: [UserRole.Admin],
    children: [
      { key: "openplay-objects", route: "/openplay-objects", title: "Objects" },
      { key: "openplay-queries", route: "/openplay-queries", title: "Queries" },
    ],
  },
  {
    key: "_reports",
    title: "Reporting",
    icon: Mail,
    children: [
      { key: "reports", route: "/reports", title: "Reports" },
      { key: "email-alerts", route: "/email-alerts", title: "Email Alerts" },
      { key: "column-sets", route: "/column-sets", title: "Column Sets" },
      {
        key: "on-demand-reports",
        route: "/on-demand-reports",
        title: "On-Demand Reports",
        roles: [UserRole.Admin],
      },
    ],
  },
  {
    key: "_webhooks",
    title: "Webhooks",
    icon: Link,
    children: [
      { key: "webhooks", route: "/webhooks", title: "All Webhooks" },
      { key: "webhook-activity", route: "/webhook-activity", title: "Webhook Activity" },
    ],
  },
  {
    key: "_flows",
    title: "Flows",
    icon: Flow,
    children: [
      { key: "flows", route: "/flows", title: "All Flows" },
      { key: "flow-events", route: "/flow-events", title: "Flow Events" },
      { key: "scripts", route: "/scripts", title: "Scripts" },
    ],
  },
  {
    key: "settings",
    route: "/settings",
    title: "Settings",
    roles: [UserRole.Admin],
    icon: Gears,
  },
];

export const Sidebar = ({ collapsed }) => {
  const { pathname } = useLocation();

  const { user } = useAuth();
  const userRole = user?.role;

  const activeTab = R.pipe(R.split("/"), R.filter(isNotEmpty), R.head)(pathname);

  const isVisible = useCallback(
    (item) => userRole === UserRole.Superadmin || checkRoles(item.roles, userRole),
    [userRole]
  );

  const items = useMemo(() => {
    return menu.filter(isVisible).map(({ title, icon, route, key, children }) => {
      const visibleChildren = children?.filter(isVisible);
      return {
        key,
        title,
        label:
          visibleChildren?.length > 0 ? (
            <div className="menu-item">
              <Icon component={icon} className="menu-item__icon" />
              <span>{title}</span>
            </div>
          ) : (
            <LinkWithConfirmation to={route} className="menu-item">
              <Icon component={icon} className="menu-item__icon" />
              <span>{title}</span>
            </LinkWithConfirmation>
          ),
        children: visibleChildren?.map((child) => ({
          key: child.key,
          title: child.title,
          label: <LinkWithConfirmation to={child.route}>{child.title}</LinkWithConfirmation>,
        })),
      };
    });
  }, [isVisible]);

  return (
    <Sider className="sidebar" width="var(--sidebar-width)" collapsible collapsed={collapsed} trigger={null}>
      <div className="logo">
        <Logo size={LogoSize.Small} color={LogoColor.WarmGrey} width={40} height={40} />
      </div>
      <Menu theme="dark" mode="inline" selectedKeys={[activeTab]} items={items} />
    </Sider>
  );
};
