import { Form, Input, Popconfirm } from "antd";
import { TemplateType } from "@/utils/template-type";
import { Remove } from "../../../common/Icons";
import { SaveAsAssetNamingTemplate } from "../SaveAsTemplate/SaveAsAssetNamingTemplate";
import { CopyFromTemplate } from "../../../common/CopyFromTemplate/CopyFromTemplate";
import { parseAssetNaming } from "../../../common/CommonMappings/utils";
import { AssetNamingFields } from "../../../common/AssetNamingFields/AssetNamingFields";
import { IconButton } from "../../../common/IconButton/IconButton";
import { Box } from "../../../common/Box/Box";

export const AssetNamingForm = ({ name, onRemove }) => {
  const fieldName = ["assetNamings", ...name];
  const form = Form.useFormInstance();
  const assetType = Form.useWatch([...fieldName, "assetType"]);

  const handleCopyFromTemplate = (template) => {
    const naming = parseAssetNaming({
      delimiter: template.data.delimiter,
      segments: template.data.segments,
    });
    form.setFieldValue([...fieldName, "delimiter"], naming.delimiter);
    form.setFieldValue([...fieldName, "customDelimiter"], naming.customDelimiter);
    form.setFieldValue([...fieldName, "segments"], naming.segments);
  };

  return (
    <Box
      title={`${assetType} naming`}
      className="asset-naming-form"
      actions={[
        <CopyFromTemplate
          filter={{
            type: TemplateType.AssetNaming,
            assetType: assetType,
          }}
          onCopy={handleCopyFromTemplate}
          buttonType="text"
          key="copy-from-template"
        />,
        <SaveAsAssetNamingTemplate outerFormField={fieldName} buttonType="text" key="save-as-template" />,
        <Popconfirm
          title="Are you sure to remove the naming?"
          onConfirm={onRemove}
          okText="Yes"
          cancelText="No"
          key="remove"
        >
          <IconButton className="asset-naming-form__remove" icon={Remove} />
        </Popconfirm>,
      ]}
    >
      <Form.Item name={[...name, "id"]} noStyle>
        <Input type="hidden" />
      </Form.Item>
      <AssetNamingFields
        getFieldName={(localName) => [name, localName]}
        getAbsoluteFieldName={(localName) => [...fieldName, localName]}
      />
    </Box>
  );
};
