import type { MessageType } from "@/api/webhooks/types";

const AUTH_URL = "/api/auth";
const PARTNERS_URL = "/api/partners";
export const FEEDS_URL = "/api/feeds";
const RELEASES_URL = "/api/releases";

const DELIVERIES_URL = "/api/deliveries";
export const DICTIONARIES_URL = "/api/dictionaries";

export const SIGN_IN_URL = `${AUTH_URL}/sign-in`;
export const LOG_OUT_URL = `${AUTH_URL}/sign-out`;

export const RESET_PASSWORD_URL = `${AUTH_URL}/reset-password`;

export const PARTNER_LIST_URL = PARTNERS_URL;
export const PARTNER_CREATE_URL = PARTNER_LIST_URL;
export const PARTNER_FIND_URL = `${PARTNERS_URL}/find`;
export const PARTNER_URL = (partnerId) => `${PARTNER_LIST_URL}/${partnerId}`;
export const PARTNER_DELIVERIES_URL = (partnerId) => `${PARTNER_URL(partnerId)}/deliveries`;
export const PARTNER_DEACTIVATION_CHECK_URL = (userId) => `${PARTNER_LIST_URL}/deactivate/${userId}`;
export const PARTNER_LOG_URL = (partnerId) => `${PARTNER_URL(partnerId)}/log`;

export const FEED_LIST_URL = (partnerId) => `${PARTNER_URL(partnerId)}/feeds`;
export const FEED_CREATE_URL = FEEDS_URL;
export const FEED_FIND_URL = `${FEEDS_URL}/find`;
export const FEED_TEST_STORAGE_URL = `${FEEDS_URL}/storage/test`;
export const FEED_DEFAULT_MAPPINGS_URL = `${FEEDS_URL}/mappings/default`;

export const ASSET_NAMING_SEGMENTS_DICTIONARY_URL = `${FEEDS_URL}/dictionaries/asset-naming-segments`;

export const FEED_URL = (feedId) => `${FEEDS_URL}/${feedId}`;
export const MANUAL_EXPORT_URL = (feedId) => `${FEED_URL(feedId)}/manual-export`;
export const FEED_DELIVERIES_URL = (feedId) => `${FEED_URL(feedId)}/deliveries`;
export const FEED_DUPLICATE_URL = (feedId) => `${FEED_URL(feedId)}/duplicate`;
export const FEED_LOG_URL = (feedId) => `${FEED_URL(feedId)}/log`;

export const DELIVERY_RESEND_URL = `${DELIVERIES_URL}/resend`;
export const PROCESS_ACKNOWLEDGEMENT_URL = `${DELIVERIES_URL}/acknowledgements/sweep`;
export const CHECK_ACKNOWLEDGEMENT_SWEEP_STATUS_URL = `${DELIVERIES_URL}/acknowledgements/status`;
export const DELIVERY_DOWNLOAD_URL = (filename) => `${RELEASES_URL}/download-delivery/${filename}`;

export const TEMPLATES_URL = "/api/templates";
export const TEMPLATE_URL = (id: string) => `${TEMPLATES_URL}/${id}`;
export const TEMPLATE_FIND_URL = `${TEMPLATES_URL}/find`;

export const ACTIVITY_ENTRIES_URL = "/api/activity/entries";

export const UPDATE_DICTIONARIES_URL = `${DICTIONARIES_URL}/update`;

export const SALESFORCE_MESSAGES_URL = "/api/salesforce-messages";
export const WEBHOOKS_INFO_URL = "/api/webhook-info";
export const WEBHOOK_INFO_URL = (type: MessageType) => `${WEBHOOKS_INFO_URL}/${type}`;
export const WEBHOOK_LOG_URL = (type: MessageType) => `${SALESFORCE_MESSAGES_URL}/${type}/log`;

export const EMAIL_SENDER_DOMAINS_URL = "/api/email-sender-domains";
