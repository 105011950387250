import { useSalesforceQueryBuilderConfig } from "./config";
import { QueryBuilder } from "../QueryBuilder/QueryBuilder";
import type { ComponentProps } from "react";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { Alert } from "antd";

interface SalesforceQueryBuilderProps extends Omit<ComponentProps<typeof QueryBuilder>, "config"> {
  from: string;
}

export const SalesforceQueryBuilder = ({
  value,
  onChange,
  from,
  onBlur
}: SalesforceQueryBuilderProps) => {
  const { config, loading } = useSalesforceQueryBuilderConfig(from);

  if (loading) {
    return <Spinner height={70} />;
  }

  return config ? (
    <QueryBuilder value={value} onChange={onChange} onBlur={onBlur} config={config} />
  ) : (
    <Alert message={`Please, import '${from}' object from Salesforce to use this functionality`} />
  );
};
