import React, { useCallback, useMemo } from "react";
import FilterableContent from "../../Layout/FilterableContent";
import { getFilters } from "./filters";
import PartnersTable from "./PartnersTable";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import Icon from "@ant-design/icons";
import { PartnerTab, useAuth, useIsAllowedForRoles, UserRole } from "@/utils";
import { Plus } from "../../common/Icons";

export function PartnersList() {
  const history = useHistory();
  const couldAddNew = useIsAllowedForRoles(UserRole.Admin);
  const handleNew = useCallback(() => history.push(`/partners/new/${PartnerTab.Summary}`), [history]);
  const button = useMemo(
    () =>
      couldAddNew
        ? [
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: "auto" }}
              key="new-partner"
              onClick={handleNew}
              icon={<Icon component={Plus} />}
            >
              New Partner
            </Button>,
          ]
        : [],
    [couldAddNew, handleNew]
  );

  const { user } = useAuth();

  return (
    <>
      <h1>Partners</h1>
      <FilterableContent
        formConfig={getFilters(user?.role)}
        filterName="partners-filter"
        isFiltersConfigurable={false}
        buttons={button}
      >
        <PartnersTable />
      </FilterableContent>
    </>
  );
}
