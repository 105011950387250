import { createContext } from "react";
import type { Dictionaries } from "@/contexts/dictionaries/types";

type DictionariesContextType = {
  readonly dictionaries: Dictionaries;
  setDictionaries: (dictionaries: Dictionaries) => void;
};

export const DictionariesContext = createContext<DictionariesContextType>({
  dictionaries: null,
  setDictionaries: () => {},
});
