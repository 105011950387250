import React from "react";
import { Col, DatePicker, Form, Input, Radio, Row, Select, Switch } from "antd";
import {
  AssetsFormat,
  DATE_FORMAT,
  DeliveryType,
  dictionaryToOptions,
  disabledEndDate,
  disabledStartDate,
  getDeliveryTypeLabel,
  getStatusLabel,
  Operation,
  Status,
  statuses,
  validateEndDate,
} from "@/utils";
import { OpenplayObjectType } from "@/types/common";
import PropTypes from "prop-types";
import { useDictionaries } from "@/utils/useDictionaries";
import { isFeedStatusChanged, isUniqueFeedName } from "../utils";
import { toOptions } from "@/utils/toOptions";
import { hasLabelInFilters } from "../../common/ReleaseFilters/utils";
import { Box } from "../../common/Box/Box";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "../../common/Icons";
import { requiredIfNotDraft } from "@/utils/validation";

const { TextArea } = Input;

export default function FeedSummaryForm({ disabled = false }) {
  const filter = Form.useWatch(["filter", "configuration"]);

  const isLabelSetOnFilter = hasLabelInFilters(filter);

  const { dictionaries } = useDictionaries();
  const status = Form.useWatch("status");
  const operation = Form.useWatch("operation");
  const checkAcknowledgements = Form.useWatch("checkAcknowledgements");
  const targetObject = Form.useWatch("targetObject");
  const deliveryType = Form.useWatch("deliveryType");
  const isInactive = status === Status.Inactive;
  const isDraft = status === Status.Draft;

  const startDate = Form.useWatch("startDate");

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Box readOnly={disabled}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Feed Name"
                validateTrigger={["onChange", "onBlur"]}
                rules={[{ required: true }, isUniqueFeedName]}
              >
                <Input placeholder="Feed Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="description" label="Description">
                <TextArea placeholder="Description" row={4} />
              </Form.Item>
            </Col>
          </Row>
        </Box>
      </Col>
      <Col span={24}>
        <Box readOnly={disabled}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="status" label="Status">
                <Radio.Group
                  buttonStyle="solid"
                  options={toOptions(statuses, getStatusLabel)}
                  optionType="button"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tenantCode"
                label="Tenant Name"
                shouldUpdate={isFeedStatusChanged}
                tooltip={
                  isLabelSetOnFilter &&
                  "Please remove filter rules containing 'label' to enable tenant code selection"
                }
                rules={[requiredIfNotDraft]}
              >
                <Select
                  placeholder="Select Tenant"
                  options={dictionaryToOptions(dictionaries.openplay.tenants)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showArrow={false}
                  showSearch
                  allowClear={isDraft}
                  optionFilterProp="label"
                  disabled={isLabelSetOnFilter}
                  suffixIcon={<Icon component={DropdownArrow} />}
                />
              </Form.Item>
            </Col>
            {isInactive && (
              <Col span={12}>
                <Form.Item name="deactivationReason" label="Deactivation Reason" rules={[requiredIfNotDraft]}>
                  <Input placeholder="Deactivation Reason" />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item name="startDate" label="Start Date">
                    <DatePicker
                      format={DATE_FORMAT}
                      disabledDate={disabledStartDate}
                      placeholder="MM/DD/YYYY"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="endDate"
                    label="End Date"
                    rules={[
                      {
                        validator: validateEndDate,
                      },
                    ]}
                  >
                    <DatePicker
                      format={DATE_FORMAT}
                      disabledDate={disabledEndDate(startDate)}
                      placeholder="MM/DD/YYYY"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>
      </Col>
      {targetObject === OpenplayObjectType.Release && (
        <Col span={24}>
          <Box readOnly={disabled}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item name="deliveryType" label="Delivery type">
                  <Radio.Group
                    buttonStyle="solid"
                    options={toOptions(Object.values(DeliveryType), getDeliveryTypeLabel)}
                    optionType="button"
                  />
                </Form.Item>
              </Col>
              {operation === Operation.Export && deliveryType === DeliveryType.AssetsAndData && (
                <Col span={12}>
                  <Form.Item name="assetsFormat" label="Assets format">
                    <Radio.Group
                      buttonStyle="solid"
                      options={toOptions(Object.values(AssetsFormat))}
                      optionType="button"
                    />
                  </Form.Item>
                </Col>
              )}
              {operation === Operation.Export && (
                <Col span={12}>
                  <Form.Item
                    name="checkAcknowledgements"
                    label="Process acknowledgements"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              )}
              {checkAcknowledgements && (
                <Col span={12}>
                  <Form.Item
                    tooltip="Default: acknowledgements"
                    name="acknowledgementPath"
                    label="Acknowledgements Path"
                  >
                    <Input placeholder="acknowledgements" />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Box>
        </Col>
      )}
    </Row>
  );
}

FeedSummaryForm.propTypes = {
  disabled: PropTypes.bool,
};
