import { Col, Form, Input, Row, Switch } from "antd";
import { isEmpty, useIsAllowedForRoles, UserRole } from "@/utils";
import PropTypes from "prop-types";
import { StatusSwitch } from "../../../common/StatusSwitch/StatusSwitch";
import { isUniqueField } from "../../utils";
import { Box } from "../../../common/Box/Box";
import { UsersSelect } from "./UsersSelect";

export default function PartnerDetailsForm({ disabled, initialUsers }) {
  const canAssignUsers = useIsAllowedForRoles(UserRole.Admin);
  const canDeactivate = canAssignUsers;

  const onHoldDays = +Form.useWatch("onHoldDays");
  const isActive = Form.useWatch("isActive");
  const isNewPartner = !Form.useWatch("id");

  const convertStringToNumber = (value) => {
    const convertedValue = Number(value);
    return isNaN(convertedValue) ? 1 : convertedValue;
  };

  const validateOnHoldDay = (_, value) => {
    if (isEmpty(value)) {
      return Promise.resolve();
    }
    const valueToNumber = convertStringToNumber(value);
    if (0 <= valueToNumber && valueToNumber <= 90) {
      return Promise.resolve();
    }
    return Promise.reject("On-hold days must be between 0 and 90");
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Box readOnly={disabled}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Partner Name"
                validateTrigger={["onChange", "onBlur"]}
                rules={[{ required: true }, isUniqueField("name", "name")]}
              >
                <Input placeholder="Partner Name" disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="ddexPartyId"
                label="DDEX Party ID"
                validateTrigger={["onChange", "onBlur"]}
                rules={[{ required: true }, isUniqueField("ddexPartyId", "DDEX Party ID")]}
              >
                <Input placeholder="DDEX Party ID" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
        </Box>
      </Col>
      <Col span={24}>
        <Box readOnly={disabled}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="primaryContactName" label="Primary Contact Name" rules={[{ required: true }]}>
                <Input placeholder="Primary Contact Name" disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="primaryContactEmail"
                label="Email"
                validateTrigger={["onChange", "onBlur"]}
                rules={[
                  { required: true },
                  {
                    type: "email",
                    message: "Invalid email format",
                  },
                  isUniqueField("primaryContactEmail", "email"),
                ]}
              >
                <Input placeholder="Email" disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="address" label="Partner Address">
                <Input placeholder="Partner Address" disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="primaryContactPhone" label="Phone">
                <Input placeholder="Phone" disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
        </Box>
      </Col>
      <Col span={24}>
        <Box readOnly={disabled}>
          <Row gutter={[16, 16]}>
            {canAssignUsers && (
              <>
                <Col span={12}>
                  <Form.Item name="users" label="Assigned users">
                    <UsersSelect disabled={disabled || !isActive} />
                  </Form.Item>
                </Col>
              </>
            )}
            {!isNewPartner && (
              <Col span={12}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item
                      name="isActive"
                      label="Status"
                      valuePropName="checked"
                      className="partner-details-form__user-status"
                      tooltip={
                        !isActive &&
                        "All operational users will lose access to the partner after deactivation"
                      }
                    >
                      <StatusSwitch disabled={disabled || !canDeactivate} />
                    </Form.Item>
                  </Col>
                  {!isActive && (
                    <Col span={24}>
                      <Form.Item
                        name="deactivationReason"
                        label="Deactivation Reason"
                        rules={[{ required: true }]}
                      >
                        <Input.TextArea placeholder="Deactivation Reason" disabled={disabled} />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
            )}
            <Col span={12}>
              <Form.Item
                name="onHoldDays"
                label="On-hold days"
                rules={[{ validator: validateOnHoldDay, validateTrigger: "onSubmit" }]}
              >
                <Input type="number" placeholder="On-hold days" disabled={disabled} min={0} max={90} />
              </Form.Item>
            </Col>
            {!!onHoldDays && (
              <Col span={12}>
                <Form.Item
                  name="usePreorderDate"
                  label="Use Pre-Order Date for On-Hold Days"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Box>
      </Col>
    </Row>
  );
}

PartnerDetailsForm.propTypes = {
  withStatus: PropTypes.bool,
  errors: PropTypes.object,
  partnersWithoutUsers: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
};
