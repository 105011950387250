import { truncate } from "@/utils";
import { BaseTag } from "@/components/common/BaseTag/BaseTag";
import "./RelatedEntityTag.less";

type EntityType = "report" | "alert" | "on-demand-report";

export type RelatedEntity = {
  type: EntityType;
  id: string;
  name: string;
};

type Props = {
  entity: RelatedEntity;
};

const labelLookup: Record<EntityType, string> = {
  report: "Report",
  alert: "Email Alert",
  "on-demand-report": "On-Demand Report",
};

export const RelatedEntityTag = ({ entity }: Props) => {
  return (
    <BaseTag bodyClassName="related-entity-tag__body">
      <span className="related-entity-tag__type">{labelLookup[entity.type]}</span>
      <span>{truncate(entity.name)}</span>
    </BaseTag>
  );
};
