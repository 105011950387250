import { Status } from "@/utils/status";
import { capitalize } from "@/utils/utils";
import type { FormInstance, Rule, ValidatorRule } from "rc-field-form/lib/interface";

type Condition =
  | [(form: FormInstance, ...args: any[]) => boolean, ...any[]]
  | ((form: FormInstance) => boolean);

export const requiredIf = (form: FormInstance, conditions: Condition[], message = "Field is mandatory") => {
  const required = conditions.every((condition) => {
    const [fn, ...args] = Array.isArray(condition) ? condition : [condition];
    return fn(form, ...args);
  });
  return { required, message };
};

export const isUniqueName = (
  findByName: (name: string) => Promise<{ id: string | number }>,
  message: string
) => ({ getFieldValue }) => {
  const id = getFieldValue("id");
  return {
    transform: (value: string) => value?.trim(),
    validateTrigger: ["onBlur"],
    validator: async (_: any, value: string) => {
      if (value?.length) {
        const entity = await findByName(value);
        if (entity && id !== entity.id) {
          return Promise.reject(message);
        }
      }
    },
  };
};

export const isNotDraft = (form: FormInstance) => form.getFieldValue("status") !== Status.Draft;

export const requiredIfNotDraft = (form: FormInstance) => requiredIf(form, [[isNotDraft]]);

export const getFieldMaxLengthRule = (name: string, max: number): Rule => ({
  type: "string",
  max,
  message: `${capitalize(name)} must not be longer than ${max} characters`,
});

export const atLeastOne = (message = "At least one item should be added"): ValidatorRule => ({
  validator: (_, value) => (value?.length > 0 ? Promise.resolve() : Promise.reject(message)),
});
