import React, { useCallback, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { useRequest } from "ahooks";
import { duplicateFeed } from "../../../api/feeds";
import Autocomplete from "../../common/Autocomplete";
import { searchPartner } from "../../../api/partners";
import { FeedTab } from "../../../utils";
import { isUniqueFeedName } from "../utils";
import { IconButton } from "../../common/IconButton/IconButton";
import { Close, Copy } from "../../common/Icons";

export const DuplicateFeedButton = ({ data: { name: feedName, id: feedId } }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [isVisible, setVisible] = useState(false);
  const { loading, run: runDuplicateFeed } = useRequest(duplicateFeed, {
    manual: true,
    throwOnError: true,
    onSuccess: () => setVisible(false),
  });
  const showModal = () => {
    setVisible(true);
  };
  const handleSave = useCallback(
    (values) => {
      runDuplicateFeed(feedId, values)
        .then((data) => {
          history.push(`/partners/${data.partnerId}/feeds/${data.id}/${FeedTab.Summary}`, {
            editMode: true,
          });
        })
        .catch((e) => {
          console.error("unhandled error", e);
        });
    },
    [feedId, runDuplicateFeed, history]
  );

  const handleCancel = () => setVisible(false);
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton icon={Copy} onClick={showModal} />
      <Modal
        visible={isVisible}
        title={`Duplicate feed '${feedName}'?`}
        footer={
          <>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" loading={loading} onClick={form.submit}>
              Duplicate
            </Button>
          </>
        }
        onCancel={handleCancel}
        className="duplicate-feed-popup"
        closeIcon={<Close />}
      >
        <Form
          form={form}
          onFinish={handleSave}
          colon={false}
          labelAlign="left"
          wrapperCol={{ span: 15, offset: 1 }}
          labelCol={{ span: 8 }}
        >
          <Form.Item
            name="name"
            label="New Feed Name"
            validateTrigger={["onChange", "onBlur"]}
            rules={[{ required: true }, isUniqueFeedName]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="partnerId"
            label="partner"
            wrapperCol={{ span: 15, offset: 1 }}
            labelCol={{ span: 8 }}
            className="select-partners"
            rules={[
              {
                required: true,
                message: "Field is mandatory.",
              },
            ]}
          >
            <Autocomplete search={searchPartner("name")} disabled={false} multiselect={false} withEmpty />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
