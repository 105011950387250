import { Button } from "antd";
import { useDownload } from "@/utils/useDownload";
import { exportReport } from "@/api/reports";
import { ExportOutlined } from "@ant-design/icons";

type Props = {
  id: string;
};

export const ExportReportButton = ({ id }: Props) => {
  const { start: download, loading } = useDownload(() => exportReport(id), {
    message: "File is being generated. Download will start automatically",
  });
  return (
    <Button icon={<ExportOutlined />} onClick={download} loading={loading}>
      Export as .JSON
    </Button>
  );
};
