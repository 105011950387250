import { Form, Select } from "antd";
import { useDictionaries } from "@/utils/useDictionaries";
import { useFieldPath } from "@/components/common/Form/hooks";
import { useMemo } from "react";
import * as R from "ramda";
import { Field } from "@/components/common/Form/Field";
import { dictionaryToOptions } from "@/utils";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import "./OpenPlayLabelPicker.less";

export const OpenPlayLabelPicker = () => {
  const form = Form.useFormInstance();
  const { dictionaries } = useDictionaries();
  const { getAbsolutePath } = useFieldPath();

  const tenants = useMemo(() => dictionaries?.openplay.tenants ?? [], [dictionaries]);
  const tenantsByCode = useMemo(() => R.indexBy(R.prop("code"), tenants), [tenants]);
  const tenantCode = Form.useWatch(getAbsolutePath("tenantCode"));

  const labels = tenantsByCode[tenantCode]?.labels ?? [];

  const handleTenantChange = () => {
    form.setFieldValue(getAbsolutePath("openplayLabelId"), null);
  };

  return (
    <div className="openplay-label-picker">
      <Field name="tenantCode" rules={[{ required: true, message: "Please, select OpenPlay tenant" }]}>
        <Select
          placeholder="Tenant Code"
          options={dictionaryToOptions(tenants)}
          onChange={handleTenantChange}
          suffixIcon={<Icon component={DropdownArrow} />}
        />
      </Field>
      <Field name="openplayLabelId" rules={[{ required: true, message: "Please, select OpenPlay label" }]}>
        <Select
          placeholder="OpenPlay Label"
          options={dictionaryToOptions(labels)}
          suffixIcon={<Icon component={DropdownArrow} />}
        />
      </Field>
    </div>
  );
};
