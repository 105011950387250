import { Button, Form, PageHeader, Popconfirm } from "antd";
import { useHistory } from "react-router-dom";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import { useBoolean } from "ahooks";
import { Summary } from "@/components/common/Summary/Summary";
import { FormActions } from "@/components/common/FormActions";
import { HiddenField } from "@/components/common/HiddenField";
import { Box } from "@/components/common/Box/Box";
import { useMemo } from "react";
import { ExportScheduleFields } from "./ExportScheduleFields";
import type { ExportSchedule } from "@/api/export-schedules/types";
import { useExportScheduleMutation } from "@/api/export-schedules/hooks";
import { deleteExportSchedule } from "@/api/export-schedules";
import { ExportScheduleTypeSelector } from "./ExportScheduleTypeSelector";
import type { FormValues } from "./utils";
import { parseExportScheduleFields, prepareExportScheduleDto } from "./utils";

type Props = {
  initialValues?: Partial<ExportSchedule>;
  isEditingByDefault?: boolean;
};

export const ExportScheduleForm = ({ initialValues = {}, isEditingByDefault = false }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const history = useHistory();
  const { allowNavigation, preventNavigation } = useNavigationConfirm();

  const [editing, { setTrue: enableEditing, setFalse: disableEditing }] = useBoolean(isEditingByDefault);

  const feed = Form.useWatch(["feed"], form);

  const initialFields = useMemo(() => parseExportScheduleFields(initialValues), [initialValues]);

  const isTypeSelected = !!feed?.id;

  const title = "Export Schedule";
  const isDeletable = !!initialFields.id && !editing;

  const { trigger: save, isMutating: saving } = useExportScheduleMutation(initialFields.id);

  const handleSave = async (values: FormValues) => {
    try {
      //@ts-expect-error
      const { id } = await save(prepareExportScheduleDto(values));
      allowNavigation();
      if (!initialFields.id) {
        history.push(`/export-schedules/${id}`);
        return;
      }
      disableEditing();
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  const handleFinish = async (values: FormValues) => {
    await handleSave(values);
  };

  const handleDelete = async () => {
    if (initialFields.id) {
      await deleteExportSchedule(initialFields.id);
      history.push(`/export-schedules`);
    }
  };

  const handleCancel = () => {
    allowNavigation();
    if (initialFields.id) {
      form.setFieldsValue(initialFields);
      disableEditing();
    } else {
      history.push(`/export-schedules`);
    }
  };

  const handleValueChange = () => {
    if (editing) {
      preventNavigation();
    }
  };

  const handleEdit = () => {
    enableEditing();
  };

  const handleTypeSelect = ({ feed }) => {
    form.setFieldValue(["feed"], feed);
  };

  return (
    <>
      <PageHeader
        title={<h1 style={{ margin: 0 }}>{title}</h1>}
        extra={
          <>
            {feed && (
              <div style={{ marginRight: 16 }}>
                <Summary items={[{ label: "Feed", value: feed.name }]} />
              </div>
            )}
            <FormActions
              isEditing={editing}
              onSave={form.submit}
              isSaving={saving}
              onEdit={handleEdit}
              onCancel={handleCancel}
            />
            {isDeletable && (
              <Popconfirm
                title="Are you sure to delete the export schedule?"
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button>Delete</Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <Form
        form={form}
        onFinish={handleFinish}
        onValuesChange={handleValueChange}
        validateMessages={{ required: "Field is mandatory" }}
        labelAlign="left"
        initialValues={initialFields}
      >
        <HiddenField name="id" />
        <HiddenField name={["feed", "id"]} />
        <HiddenField name={["feed", "name"]} />
        <HiddenField name={["feed", "targetObject"]} />
        <HiddenField name="source" />
        {isTypeSelected ? (
          <Box readOnly={!editing}>
            <ExportScheduleFields targetObject={feed.targetObject} />
          </Box>
        ) : (
          <ExportScheduleTypeSelector onSelect={handleTypeSelect} />
        )}
      </Form>
    </>
  );
};
