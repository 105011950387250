import * as R from "ramda";
import { isExist, stripUndefined } from "@/utils";
import { api } from "../core";
import {
  ASSET_NAMING_SEGMENTS_DICTIONARY_URL,
  FEED_CREATE_URL,
  FEED_DEFAULT_MAPPINGS_URL,
  FEED_DELIVERIES_URL,
  FEED_DUPLICATE_URL,
  FEED_FIND_URL,
  FEED_LIST_URL,
  FEED_LOG_URL,
  FEED_TEST_STORAGE_URL,
  FEED_URL,
  MANUAL_EXPORT_URL,
} from "../urls";

export const fetchFeeds = async (partnerId, params) => {
  const response = await api.get(FEED_LIST_URL(partnerId), { params });
  return {
    ...response,
    data: response.data.map((row) => {
      if (isExist(row.startDate)) {
        row.startDate = new Date(row.startDate);
      }
      if (isExist(row.endDate)) {
        row.endDate = new Date(row.endDate);
      }
      if (isExist(row.updatedAt)) {
        row.updatedAt = new Date(row.updatedAt);
      }
      if (isExist(row.createdAt)) {
        row.createdAt = new Date(row.createdAt);
      }
      return row;
    }),
  };
};

const prepareFeed = (data) => {
  if (data.filter) {
    if (R.isNil(data.filter.id)) {
      delete data.filter.id;
    }
  }
  if (data?.tenantCode === undefined) {
    data.tenantCode = null;
  }
  if (isExist(data.startDate)) {
    data.startDate = data.startDate.utcOffset(0).startOf("day").toISOString();
  }
  if (isExist(data.endDate)) {
    data.endDate = data.endDate.utcOffset(0).endOf("day").toISOString();
  }
  return data;
};

export function createFeed(data) {
  return api.post(FEED_CREATE_URL, prepareFeed(data));
}
export function updateFeed(feedId, data) {
  return api.patch(FEED_URL(feedId), prepareFeed(data));
}

export function saveFeed(fields) {
  const cleanedUpFields = stripUndefined(fields);
  return "id" in cleanedUpFields
    ? updateFeed(cleanedUpFields.id, cleanedUpFields)
    : createFeed(cleanedUpFields);
}

export async function fetchFeed(id) {
  return api.get(FEED_URL(id)).then((data) => data);
}

export async function deleteFeed(id) {
  return api.delete(FEED_URL(id));
}

export async function duplicateFeed(id, data) {
  return api.post(FEED_DUPLICATE_URL(id), data);
}

export async function testStorageService({ type, configuration }) {
  if (configuration?.port) {
    configuration.port = Number(configuration.port);
  }
  return api.post(FEED_TEST_STORAGE_URL, { type, ...configuration });
}

export async function manualExport(feedId, formData, validate = false) {
  return api.post(MANUAL_EXPORT_URL(feedId), formData, { params: { validate } });
}

export async function getDeliveriesByFeedId(feedId, params) {
  return api.get(FEED_DELIVERIES_URL(feedId), { params });
}

export async function mappingSettings(inputFormat, outputFormat) {
  return api.get(FEED_DEFAULT_MAPPINGS_URL, { params: { inputFormat, outputFormat } });
}

export async function feedLogExport(feedId) {
  return api.get(FEED_LOG_URL(feedId), {
    responseType: "blob",
  });
}

export async function getAssetNamingSegments() {
  return api.get(ASSET_NAMING_SEGMENTS_DICTIONARY_URL);
}

export async function findFeedByName(name) {
  return await api.get(FEED_FIND_URL, { params: { name } });
}
