import axios from "axios";
import { openNotification } from "@/components/common/CommonNotification";
import { ASSET_NAMING_SEGMENTS_DICTIONARY_URL, DICTIONARIES_URL, SIGN_IN_URL } from "./urls";
import { ERROR_MESSAGES } from "./messages";
import { stringify } from "qs";

export const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8",
  },
  paramsSerializer: stringify,
});

const ENDPOINTS_WITHOUT_REFRESH = [
  `/api/users/me`,
  SIGN_IN_URL,
  DICTIONARIES_URL,
  ASSET_NAMING_SEGMENTS_DICTIONARY_URL,
];

api.interceptors.response.use(
  (response) => {
    if (response.config.responseType === "blob") {
      return response;
    }
    return response.data;
  },
  (error) => {
    if (error?.response?.status === 401) {
      if (!ENDPOINTS_WITHOUT_REFRESH.includes(error.response.config.url)) {
        window.location.reload();
      }
    } else if (
      !ERROR_MESSAGES.hasOwnProperty(error?.response?.data.errorCode) &&
      error?.response?.status !== 404
    ) {
      openNotification({
        type: "error",
        message: error.message,
        description: error?.response?.data?.message ?? error?.message,
      });
    }
    if (error?.response?.data) {
      return Promise.reject(error?.response?.data);
    } else {
      return Promise.reject(error);
    }
  }
);
