import { ExtraActions } from "@/components/common/ExtraActions/ExtraActions";
import { Link } from "react-router-dom";
import type { MenuProps } from "antd";
import { useDownload } from "@/utils/useDownload";
import { downloadPartnerLog } from "@/api/partners";

export const PartnerActions = ({ partner }) => {
  const { start: startPartnerLogDownload } = useDownload(downloadPartnerLog, {
    message: "File is being generated. Download will start automatically",
  });

  const menuItems: MenuProps["items"] = [
    {
      key: "activity",
      label: <Link to="./activity">Partner Activity</Link>,
    },
    {
      key: "log",
      onClick: () => startPartnerLogDownload(partner.id),
      label: "Download Partner Activity",
    },
  ];

  return <ExtraActions items={menuItems} />;
};
