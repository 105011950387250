import useUrlState from "@ahooksjs/use-url-state";
import { useMemo } from "react";

export const PAGINATION_PAGE_SIZE = "limit";
export const PAGINATION_PAGE_NUMBER = "page";
export const PAGINATION_DEFAULT_PAGE_SIZE = 50;

export function usePagination() {
  const [state, setState] = useUrlState(
    {
      [PAGINATION_PAGE_NUMBER]: 1,
      [PAGINATION_PAGE_SIZE]: PAGINATION_DEFAULT_PAGE_SIZE,
    },
    { navigateMode: "replace" }
  );
  const pageSize = Number(state[PAGINATION_PAGE_SIZE]);
  const pageNumber = Number(state[PAGINATION_PAGE_NUMBER]);

  const preparedState = useMemo(
    () => ({
      [PAGINATION_PAGE_NUMBER]: pageNumber,
      [PAGINATION_PAGE_SIZE]: pageSize,
    }),
    [pageSize, pageNumber]
  );

  return [preparedState, setState];
}
