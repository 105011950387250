import { useHistory } from "react-router-dom";
import { useAuth } from "../../../utils";
import { CaretDownOutlined, CaretUpOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import "./Header.less";
import React, { useCallback, useState } from "react";
import { Layout, Popover } from "antd";
import { useRequest } from "ahooks";
import { logout } from "../../../api/auth";
import { LinkWithConfirmation } from "../../../utils/useNavigationConfirm";
import cn from "classnames";
import { IconButton } from "../../common/IconButton/IconButton";

const { Header: AntdHeader } = Layout;

const content = (logout, closePopUp) => (
  <div>
    <div className="user-links">
      <LinkWithConfirmation to="/profile" onClick={closePopUp}>
        Profile
      </LinkWithConfirmation>
    </div>
    <div className="user-links">
      <LinkWithConfirmation to="#" id="qsLogoutBtn" onClick={logout}>
        Log Out
      </LinkWithConfirmation>
    </div>
  </div>
);

export const Header = ({ className, isMenuCollapsed, onCollapse }) => {
  const history = useHistory();

  const [isPopupOpen, toggle] = useState(false);
  const { user, refresh: refreshAuth } = useAuth();

  const { run: runLogout } = useRequest(logout, {
    manual: true,
    formatResult: () => true,
    onSuccess: async () => {
      refreshAuth();
      history.push("/login");
    },
  });
  const onLogout = useCallback(() => runLogout(), [runLogout]);

  const togglePopupOpen = useCallback(() => {
    toggle(!isPopupOpen);
  }, [toggle, isPopupOpen]);

  const MenuTriggerIcon = isMenuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined;

  return (
    <AntdHeader className={cn("header", className)}>
      <IconButton className="menu-trigger" onClick={onCollapse} icon={MenuTriggerIcon} />
      <div className="user-actions">
        <Popover
          placement="bottomRight"
          content={content(onLogout, togglePopupOpen)}
          trigger="click"
          onOpenChange={togglePopupOpen}
          open={isPopupOpen}
          overlayClassName="nav-bar__popover"
        >
          <span className="user-name">{user.name}</span>
          {isPopupOpen ? (
            <CaretUpOutlined style={{ color: "white" }} />
          ) : (
            <CaretDownOutlined style={{ color: "white" }} />
          )}
        </Popover>
      </div>
    </AntdHeader>
  );
};
