import { Button, Tooltip } from "antd";
import { useDownload } from "@/utils/useDownload";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { downloadSpreadsheet } from "@/api/on-demand-reports";
import { IconButton } from "@/components/common/IconButton/IconButton";

type Props = {
  reportId: string;
  iconOnly?: boolean;
};

export const DownloadSpreadsheetButton = ({ reportId, iconOnly }: Props) => {
  const { start: download, loading: downloading } = useDownload(downloadSpreadsheet, {
    message: "The spreadsheet is being generated. Download will start automatically",
    noContentMessage: "The are no records to include into the file",
  });

  const handleClick = (e) => {
    e.stopPropagation();
    return download(reportId);
  };

  if (iconOnly) {
    return (
      <Tooltip title="Generate Report">
        <IconButton onClick={handleClick} disabled={downloading}>
          {downloading ? <LoadingOutlined /> : <DownloadOutlined />}
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Button icon={<DownloadOutlined />} onClick={handleClick} loading={downloading}>
      Generate Report
    </Button>
  );
};
