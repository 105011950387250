import * as R from "ramda";
import { dictionaryToOptions } from "@/utils";
import { CustomAntdConfig } from "../QueryBuilder/config";
import type { Fields } from "@react-awesome-query-builder/core";
import type { Dictionaries } from "@/contexts/dictionaries/types";

export const conjunctions = {
  AND: CustomAntdConfig.conjunctions.AND,
  OR: CustomAntdConfig.conjunctions.OR,
};

export const operators = {
  select_equals: {
    ...CustomAntdConfig.operators.equal,
    label: "equal",
  },
  select_not_equals: {
    ...CustomAntdConfig.operators.not_equal,
    label: "not equal",
  },
  select_any_in: {
    ...CustomAntdConfig.operators.select_any_in,
    label: "any in",
  },
  select_not_any_in: {
    ...CustomAntdConfig.operators.select_not_any_in,
    label: "not in",
  },
};

export const types = {
  ...CustomAntdConfig.types,
  select: {
    mainWidget: "select",
    defaultOperator: "select_equals",
    widgets: {
      select: {
        operators: ["select_equals", "select_not_equals"],
        widgetProps: {
          customProps: {
            showSearch: true,
          },
        },
      },
      multiselect: {
        operators: ["select_any_in", "select_not_any_in"],
      },
    },
  },
};

const localeSettings = {
  valueLabel: "Value",
  valuePlaceholder: "Value",
  fieldLabel: "Field",
  operatorLabel: "Operator",
  fieldPlaceholder: "Select field",
  operatorPlaceholder: "Select operator",
  deleteLabel: null,
  addGroupLabel: "Add group",
  addRuleLabel: "Add rule",
  delGroupLabel: null,
  valueSourcesPopupTitle: "Select value source",
  removeGroupConfirmOptions: {
    title: "Are you sure you want to delete this group?",
    okText: "Yes",
    okType: "danger",
  },
};

export const settings = {
  ...CustomAntdConfig.settings,
  ...localeSettings,

  valueSourcesInfo: {
    value: {
      label: "Value",
    },
    field: {
      label: "Field",
      widget: "field",
    },
    func: {
      label: "Function",
      widget: "func",
    },
  },
  showNot: true,
  maxNesting: 5,
  canLeaveEmptyGroup: false,
  showErrorMessage: true,
  fieldSeparator: "_@_",
  renderSize: "medium",
};

const requiredValidation = (value: any) => {
  if (R.isEmpty(value)) {
    return "Empty value is not valid";
  }
};

export const getFields = (tenantCode: string, dictionaries: Dictionaries): Fields => {
  const tenant = dictionaries?.openplay.tenants.find((tenant) => tenant.code === tenantCode);
  const labels = tenant?.labels ?? [];
  return {
    "primary_genre.id": {
      label: "Genre",
      type: "select",
      valueSources: ["value"],
      operators: ["select_equals", "select_not_equals", "select_any_in", "select_not_any_in"],
      listValues: dictionaryToOptions(dictionaries?.openplay.genres ?? []),
      fieldSettings: {
        validateValue: requiredValidation,
      },
    },
    "configuration.id": {
      label: "Configuration",
      type: "select",
      valueSources: ["value"],
      operators: ["select_equals", "select_not_equals", "select_any_in", "select_not_any_in"],
      listValues: dictionaryToOptions(dictionaries?.openplay.configurations ?? []),
      fieldSettings: {
        validateValue: requiredValidation,
      },
    },
    parental_advisory: {
      label: "Parental advisory options",
      type: "select",
      valueSources: ["value"],
      operators: ["select_equals", "select_not_equals", "select_any_in", "select_not_any_in"],
      listValues: dictionaryToOptions(dictionaries?.openplay.parentalAdvisoryOptions ?? []),
      fieldSettings: {
        validateValue: requiredValidation,
      },
    },
    "configuration.type": {
      label: "Release type",
      type: "select",
      valueSources: ["value"],
      operators: ["select_equals", "select_not_equals", "select_any_in", "select_not_any_in"],
      listValues: dictionaryToOptions(dictionaries?.openplay.releaseTypes ?? []),
      fieldSettings: {
        validateValue: requiredValidation,
      },
    },
    resolution_type: {
      label: "Resolutions",
      type: "select",
      valueSources: ["value"],
      operators: ["select_equals", "select_not_equals", "select_any_in", "select_not_any_in"],
      listValues: dictionaryToOptions(dictionaries?.openplay.resolutions ?? []),
      fieldSettings: {
        validateValue: requiredValidation,
      },
    },
    "label.id": {
      label: "Labels",
      type: "select",
      valueSources: ["value"],
      operators: ["select_equals", "select_not_equals", "select_any_in", "select_not_any_in"],
      listValues: dictionaryToOptions(labels),
      fieldSettings: {
        validateValue: requiredValidation,
      },
      mainWidgetProps: {
        valuePlaceholder: labels.length > 0 ? "Select value" : "Please select tenant first",
      },
    },
  };
};

export const getConfig = (tenantCode: string, dictionaries: Dictionaries) => ({
  conjunctions,
  operators,
  types,
  settings,
  widgets: CustomAntdConfig.widgets,
  fields: getFields(tenantCode, dictionaries),
});
