import { TableWithPaginationAndSorting } from "../../common";
import React from "react";
import { ActivityEntryDetails } from "../ActivityEntryDetails/ActivityEntryDetails";
import { Operation, ORDER_DESC } from "@/utils";
import FilterableContent from "../../Layout/FilterableContent";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { toOptions } from "@/utils/toOptions";
import { getProcessingStatusFullLabel, ProcessingStatus } from "@/utils/processing-status";
import { ProcessingStatusTag } from "@/components/common/ProcessingStatusTag";

export const ActivityFilters = {
  Status: {
    name: "statuses[]",
    label: "Status",
    type: FilterType.MultiSelect,
    options: toOptions(Object.values(ProcessingStatus), getProcessingStatusFullLabel),
    tagRender: ({ value: status, closable, onClose }) => (
      <ProcessingStatusTag status={status} onClose={onClose} closable={closable} />
    ),
  },
  Operation: {
    name: "operation",
    label: "Operation",
    type: FilterType.Radio,
    options: toOptions([Operation.Import, Operation.Export]),
  },
  UPC: { name: "upcs[]", label: "UPCs", type: FilterType.UPCs },
  UpcContains: { name: "upcContains", label: "UPC Contains", type: FilterType.Text },
  StartedAt: { name: "started_at", label: "Started At", type: FilterType.DateRange },
};

export const ActivityTable = ({ entries, loading, columns, showFeedInfo = true, filters = [] }) => {
  return (
    <FilterableContent
      formConfig={filters}
      filterName="release-delivery-filter"
      isFiltersConfigurable={false}
    >
      <TableWithPaginationAndSorting
        loading={loading}
        columns={columns}
        defaultSortColumn="startedAt"
        defaultOrder={ORDER_DESC}
        data={entries}
        expandable={{
          expandedRowRender: (entry) => <ActivityEntryDetails entry={entry} showFeedInfo={showFeedInfo} />,
          rowExpandable: () => true,
        }}
        scroll={{ x: true }}
      />
    </FilterableContent>
  );
};
