import { QueryParamType } from "@/api/openplay-queries/types";
import { Input, InputNumber, Switch as AntdSwitch } from "antd";
import { DateInput } from "@/components/common/DateInput";
import { MultiSelect } from "@/components/common/MultiSelect/MultiSelect";

type Props = {
  type: QueryParamType;
  placeholder?: string;
  value?: any;
  onChange?: (value: any) => void;
};

export const QueryParameterValueInput = ({ type, value, onChange, placeholder }: Props) => {
  switch (type) {
    case QueryParamType.String:
      return <Input value={value} onChange={onChange} placeholder={placeholder} />;
    case QueryParamType.Number:
      return <InputNumber value={value} onChange={onChange} placeholder={placeholder} />;
    case QueryParamType.Date:
    case QueryParamType.DateTime:
      return <DateInput value={value} onChange={onChange} showTime={type === "datetime"} />;
    case QueryParamType.Array:
      return <MultiSelect value={value} onChange={onChange} placeholder={placeholder} mode="tags" />;
    case QueryParamType.Boolean:
      return <AntdSwitch checked={value} onChange={onChange} defaultChecked={false} />;
  }
};
