import { isEmpty } from "../../utils";
import { Form } from "antd";
import { isUniqueName } from "../../utils/validation";
import { findFeedByName } from "../../api/feeds";

export const isFeedStatusChanged = (prev, curr) => prev.status !== curr.status;

export const isEmptyField = (form, fieldName) => isEmpty(form.getFieldValue(fieldName));

export const isUniqueFeedName = isUniqueName(
  findFeedByName,
  `A feed with entered name already exists in the system`
);

export const useMappingTemplate = (form) => {
  const inputFormat = Form.useWatch(["mapping", "inputFormat"], form);
  const outputFormat = Form.useWatch(["mapping", "outputFormat"], form);
  const isSelected = inputFormat && outputFormat;
  return { inputFormat, outputFormat, isSelected };
};
