import { useAuth } from "./useAuth";
import * as R from "ramda";
import { isEmpty } from "./utils";
import { UserRole } from "@/utils/user-role";

export const useIsAllowedForRoles = (...roles) => {
  const { user } = useAuth();
  if (user?.role === UserRole.Superadmin) {
    return true;
  }
  if (roles?.length < 2 && Array.isArray(roles[0])) {
    roles = roles[0];
  }
  return checkRoles(roles, user?.role);
};

export const checkRoles = (roles, currentRole) => R.either(isEmpty, R.includes(currentRole))(roles);
