import { capitalize, getStatusLabel, prepareParams, ReportTab } from "@/utils";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { Button, PageHeader } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "../../common/Icons";
import { TableWithPaginationAndSorting } from "../../common";
import { columns } from "./columns";
import { fetchReports } from "@/api/reports";
import { useGridParams } from "@/utils/useGridParams";
import { ImportReportButton } from "@/components/Reports/ImportReportButton";
import FilterableContent from "@/components/Layout/FilterableContent";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { toOptions } from "@/utils/toOptions";
import { ReportStatus } from "@/api/reports/types";
import { RecurrenceInterval } from "@/api/types";
import { useReports } from "@/api/reports/hooks";

const filterConfig = [
  {
    name: "status",
    label: "Status",
    type: FilterType.Select,
    options: toOptions(
      [ReportStatus.Active, ReportStatus.Inactive, ReportStatus.Draft].map(String),
      getStatusLabel
    ),
  },
  {
    name: "recurrency[interval]",
    label: "Interval",
    type: FilterType.Select,
    options: toOptions(Object.values(RecurrenceInterval), capitalize),
  },
  {
    name: "name",
    label: "Name",
    type: FilterType.Autocomplete,
    getSuggestions: async (query: string) => {
      const response = await fetchReports({ name: query });
      return response.data.map((report) => report.name);
    },
  },
  { name: "description", label: "Description", type: FilterType.Text },
];

export const ReportsList = () => {
  const { filters, pagination, sort } = useGridParams();

  const history = useHistory();
  const handleEdit = useCallback(({ id }) => history.push(`/reports/${id}/${ReportTab.Summary}`), [history]);

  const { data, isLoading } = useReports(prepareParams(filters, pagination, sort));

  const handleCreate = () => {
    history.push(`/reports/new/${ReportTab.Summary}`);
  };

  return (
    <>
      <PageHeader
        style={{ marginBottom: 16 }}
        title={<h1 style={{ margin: 0 }}>Reports</h1>}
        extra={[
          <ImportReportButton key="import" />,
          <Button key="new" type="primary" icon={<Icon component={Plus} />} onClick={handleCreate}>
            New Report
          </Button>,
        ]}
      />
      <FilterableContent
        formConfig={filterConfig}
        filterName="webhook-activity-filter"
        isFiltersConfigurable={false}
      >
        <TableWithPaginationAndSorting
          rowKey="id"
          onRowClick={handleEdit}
          columns={columns}
          data={data}
          loading={isLoading}
          scroll={{ x: true }}
        />
      </FilterableContent>
    </>
  );
};
