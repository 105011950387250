import { generateReport } from "@/api/releases";
import { useDownload } from "@/utils/useDownload";
import FilterableContent from "../../Layout/FilterableContent";
import { filtersConfig as filtersFormConfig } from "./config";
import { Button } from "antd";
import { ORDER_DESC, prepareParams } from "@/utils";
import { useGridParams } from "@/utils/useGridParams";
import { Import } from "../../common/Icons";
import Icon from "@ant-design/icons";
import { columns } from "@/components/Releases/List/columns";
import { TableWithPaginationAndSorting } from "@/components/common";
import { useHistory } from "react-router-dom";
import { useReleases } from "@/api/releases/hooks";

export const ReleasesList = () => {
  const { pagination, sort, filters } = useGridParams();
  const history = useHistory();

  const { loading: generatingReport, start: downloadReport } = useDownload(generateReport, {
    message: "Report is being generated. Download will start automatically",
  });

  const { isLoading, data } = useReleases(prepareParams(filters, pagination, sort));

  const handleGenerateReport = () => downloadReport(prepareParams(filters, {}, sort));

  const handleEdit = ({ id }) => history.push(`/releases/${id}`);

  return (
    <>
      <h1>Releases</h1>
      <FilterableContent
        formConfig={filtersFormConfig}
        filterName="releases"
        isFiltersConfigurable={true}
        defaultFilters={["release_date", "upcs[]", "artist", "title"]}
        buttons={[
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: "auto" }}
            key="generate-report"
            loading={generatingReport}
            onClick={handleGenerateReport}
            icon={<Icon component={Import} />}
            disabled={data.totalCount === 0}
          >
            Generate Report
          </Button>,
        ]}
      >
        <TableWithPaginationAndSorting
          loading={isLoading}
          onRowClick={handleEdit}
          columns={columns}
          defaultSortColumn="release_date"
          defaultOrder={ORDER_DESC}
          data={data}
        />
      </FilterableContent>
    </>
  );
};
