import { Field } from "@/components/common/Form/Field";
import { Remove } from "@/components/common/Icons";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { OpenplayQueryEditor } from "../OpenplayQueryEditor";
import "./OpenplayQueryListItem.less";
import { HiddenField } from "@/components/common/HiddenField";
import { useOpenplayQueries } from "@/api/openplay-queries/hooks";
import { useFieldPath } from "@/components/common/Form/hooks";
import { Col, Form, Input, Row } from "antd";
import { TenantPicker } from "@/components/OpenplayQueries/TenantPicker";
import { QueryParameterValueInput } from "@/components/OpenplayQueries/QueryParameterValueInput";
import { getFieldMaxLengthRule } from "@/utils/validation";

type Props = {
  fieldIndex: number;
  onRemove: () => void;
  removable?: boolean;
};

export const OpenplayQueryListItem = ({ fieldIndex, onRemove, removable }: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const selectedId = Form.useWatch(getAbsolutePath(["openplayQuery", "id"]));

  const {
    data: { data: queries },
    isLoading,
  } = useOpenplayQueries();

  const selectedQuery = selectedId ? queries.find((query) => query.id === selectedId) : null;

  const parameters = Object.entries(selectedQuery?.parameters ?? {});

  return (
    <div className="openplay-query-list-item">
      <HiddenField name="id" />
      <Row className="openplay-query-list-item__field" gutter={[16, 16]}>
        <Col span={12}>
          <Field label="Name" name="name" rules={[getFieldMaxLengthRule("Query name", 30)]}>
            <Input placeholder="Name (optional)" />
          </Field>
        </Col>
        <Col span={12}>
          <Field
            label="Query"
            name={["openplayQuery", "id"]}
            rules={[
              { required: true, message: "Please, select a query" },
              (form) => ({
                validator: (_, value) => {
                  const idsInUse = (form.getFieldValue(["openplayQueries"]) as { id: string }[])
                    .filter((_, index) => fieldIndex !== index)
                    .map((query) => query.id);
                  return idsInUse.includes(value)
                    ? Promise.reject("The query is already in use")
                    : Promise.resolve();
                },
              }),
            ]}
          >
            <OpenplayQueryEditor removable={removable} queries={queries} isLoading={isLoading} />
          </Field>
        </Col>
        {selectedQuery?.defaultTenant && (
          <Col span={12}>
            <Field
              label="Tenant"
              name="tenant"
              rules={[{ required: true, message: "Please, select tenant" }]}
              initialValue={selectedQuery?.defaultTenant}
            >
              <TenantPicker />
            </Field>
          </Col>
        )}
        {parameters.map(([name, { type, defaultValue }]) => (
          <Col span={12} key={name}>
            <Field
              label={name.replaceAll("_", " ")}
              name={["parameters", name]}
              initialValue={defaultValue}
              rules={[
                {
                  required: true,
                  message: "Please, provide value for the parameter",
                },
              ]}
            >
              <QueryParameterValueInput type={type} placeholder="Parameter Value" />
            </Field>
          </Col>
        ))}
      </Row>
      <IconButton icon={Remove} onClick={onRemove} disabled={!removable} />
    </div>
  );
};
