import { Button, Form } from "antd";
import { Field } from "@/components/common/Form/Field";
import { LabelGroupPicker } from "@/components/common/LabelGroupPicker";
import { CompanyPicker } from "@/components/common/CompanyPicker";
import { Box } from "@/components/common/Box/Box";
import { MappingList } from "@/components/common/MappingList";
import { useBoolean } from "ahooks";
import type { Settings } from "@/api/settings/types";
import { useSettingsMutation } from "@/api/settings/hooks";

type Props = {
  initialValues: Settings;
};

export const LabelGroupsToCompanies = ({ initialValues }: Props) => {
  const [form] = Form.useForm();

  const [isEditing, { setTrue: enableEditing, setFalse: disableEditing }] = useBoolean(false);

  const handleCancel = () => {
    form.resetFields();
    disableEditing();
  };

  const { isMutating, trigger: updateSettings } = useSettingsMutation();

  const handleFinish = async (values: Partial<Settings>) => {
    try {
      await updateSettings(values);
      disableEditing();
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  return (
    <Box
      bodyReadOnly={!isEditing || isMutating}
      actions={
        isEditing
          ? [
              <Button type="text"  key="cancel" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button type="text"  key="save" onClick={form.submit} disabled={isMutating}>
                Save
              </Button>,
          ]
          : [
              <Button type="text"  key="edit" onClick={enableEditing}>
                Edit
              </Button>,
            ]
      }
    >
      <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
        <MappingList
          name="labelGroupsToCompanies"
          left={
            <Field
              name="labelGroups"
              rules={[{ required: true, message: "Please, select on or more label groups" }]}
            >
              <LabelGroupPicker placeholder="Select Label Groups" />
            </Field>
          }
          right={
            <Field
              name="companies"
              rules={[{ required: true, message: "Please, select on or more companies" }]}
            >
              <CompanyPicker placeholder="Select Companies" />
            </Field>
          }
        />
      </Form>
    </Box>
  );
};
