import { useRequest } from "ahooks";
import { Button, Divider, PageHeader } from "antd";
import { openNotification } from "../common/CommonNotification";
import { updateDictionaries } from "@/api/dictionaries";
import { Box } from "../common/Box/Box";
import { useDictionaries } from "@/utils/useDictionaries";
import { Tooltip } from "@/components/common/Tooltip";
import { AcknowledgementSweep } from "./AcknowledgementSweep";
import { LabelGroupsToCompanies } from "./LabelGroupsToCompanies";
import { useSettings } from "@/api/settings/hooks";
import { Spinner } from "@/components/common/Spinner/Spinner";

export const SettingsPage = () => {
  const { setDictionaries } = useDictionaries();

  const { run: handleUpdateDictionaries, loading: updatingDictionaries } = useRequest(updateDictionaries, {
    manual: true,
    onSuccess: (dictionaries) => {
      setDictionaries(dictionaries);
      openNotification({ message: "Dictionaries are successfully updated" });
    },
  });

  const { isLoading, data: settings } = useSettings();

  return (
    <>
      <PageHeader title={<h1 style={{ margin: 0 }}>Settings</h1>} ghost />
      <Divider orientation="left">Dictionaries</Divider>
      <Box>
        <Tooltip title="Dictionaries are updated automatically every hour">
          <Button
            type="primary"
            onClick={handleUpdateDictionaries}
            loading={updatingDictionaries}
            disabled={updatingDictionaries}
          >
            Update Dictionaries
          </Button>
        </Tooltip>
      </Box>
      <Divider orientation="left">Acknowledgements</Divider>
      <Box>
        <AcknowledgementSweep />
      </Box>
      <Divider orientation="left">Label Groups to Companies</Divider>
      {isLoading ? <Spinner /> : <LabelGroupsToCompanies initialValues={settings} />}
    </>
  );
};
