import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { toOptions } from "@/utils/toOptions";
import { getStatusLabel, Status as StatusCode, UserRole } from "@/utils";
import { getPartners } from "@/api/partners";
import { fetchUsers } from "@/api/users";

export const getFilters = (role) =>
  [
    {
      name: "name",
      label: "Partner Name",
      type: FilterType.Autocomplete,
      getSuggestions: async (query) => {
        const response = await getPartners({ name: query });
        return response.data.map((partner) => partner.name);
      },
    },
    { name: "ddexPartyId", label: "DDEX Party ID", type: FilterType.Text },
    role >= UserRole.Admin && {
      name: "userName",
      label: "Assigned Users",
      type: FilterType.Autocomplete,
      getSuggestions: async (query) => {
        const response = await fetchUsers({ nameContains: query, role: UserRole.Operational });
        return response.data.map((user) => user.name);
      },
    },
    {
      name: "primaryContactName",
      label: "Primary Contact Name",
      type: FilterType.Text,
    },
    { name: "address", label: "Partner Address", type: FilterType.Text },
    { name: "primaryContactEmail", label: "Email", type: FilterType.Text },
    {
      name: "status",
      label: "status",
      type: FilterType.Radio,
      options: toOptions([StatusCode.Active, StatusCode.Inactive].map(String), getStatusLabel),
    },
  ].filter(Boolean);
