import type { Status, UserRole } from "@/utils";
import type { Pagination } from "@/api/types";

type Partner = {
  id: string;
  name: string;
  ddexPartyId: string;
  address?: string;
  primaryContactName: string;
  primaryContactEmail: string;
  primaryContactPhone?: string;
  status: Status;
  deactivationReason: string;
  usePreorderDate?: boolean;
  onHoldDays?: number;
  createdAt: string;
  updatedAt: string;
};

export type User = {
  id: string;
  externalId: string;
  name: string;
  email: string;
  role: UserRole;
  status: Status;
  timeZone?: string;
  createdAt: string;
  updatedAt: string;
  partners: Partner[];
};

export type UserFilters = Pagination & {
  name?: string;
  nameContains?: string;
  email?: string;
  role?: UserRole;
  status?: Status;
};

export type CreateUserDto = Partial<User> & { status: Status };

export enum PermissionType {
  Can = "can",
  Cannot = "cannot",
}

export enum PermissionAction {
  Manage = "manage",
  Create = "create",
  Read = "read",
  Update = "update",
  Delete = "delete",
}

export type Permission = {
  id: string;
  type: PermissionType;
  action: PermissionAction;
  subject: string;
};

export type PermissionSubject = {
  name: string;
  label: string;
  availableActions?: PermissionAction[];
};
