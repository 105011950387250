import { findPartner } from "../../api/partners";

export const isUniqueField = (name, label) => (form) => {
  const id = form.getFieldValue("id");
  return {
    transform: (value) => value?.trim(),
    validateTrigger: ["onBlur"],
    validator: async (_, value) => {
      if (value?.length) {
        const partner = await findPartner({ [name]: value });
        if (partner && id !== partner.id) {
          return Promise.reject(`A partner with entered ${label} already exists in the system`);
        }
      }
    },
  };
};
