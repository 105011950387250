import { getTabSourceLabel, getTemplateTypeLabel, TemplateType } from "@/utils";
import { getTemplateName } from "../../../common/CommonMappings/utils";
import Icon from "@ant-design/icons";
import { Info } from "../../../common/Icons";
import { useDictionaries } from "@/utils/useDictionaries";
import * as R from "ramda";
import "./TemplateNameCell.less";
import { Tooltip } from "@/components/common/Tooltip";

const getTooltip = (template, tenantsByCode) => {
  switch (template.type) {
    case TemplateType.Mapping:
      return getTemplateName(template.data.inputFormat, template.data.outputFormat);
    case TemplateType.Filters:
      return template.data.tenantCode ? tenantsByCode[template.data.tenantCode]?.name : null;
    case TemplateType.AssetNaming:
      return template.data.assetType;
    case TemplateType.SalesforceQuery:
      return template.data.from;
    case TemplateType.SpreadsheetColumnSet:
      return (
        `${getTabSourceLabel(template.data.source)}` +
        (template.data.sourceObject ? ` (${template.data.sourceObject})` : "")
      );
    default:
      return null;
  }
};

export const TemplateNameCell = ({ template }) => {
  const { dictionaries } = useDictionaries();
  const tenantsByCode = R.indexBy(R.prop("code"), dictionaries.openplay.tenants);
  return (
    <div className="template-name-cell">
      <Tooltip title={getTooltip(template, tenantsByCode)}>
        <Icon component={Info} className="template-name-cell__info" />
      </Tooltip>
      <div>{getTemplateTypeLabel(template.type)}</div>
    </div>
  );
};
