import { useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import * as R from "ramda";
import {
  ORDER_BY_NAME,
  ORDER_BY_SORT,
  ORDER_DEFAULT_NAME,
  ORDER_DEFAULT_SORT,
  PAGINATION_PAGE_NUMBER,
  PAGINATION_PAGE_SIZE,
  useOrderBy,
  usePagination,
} from "@/utils";
import { useMount } from "ahooks";

function TableWithPaginationAndSorting({
  data,
  columns,
  onRowClick,
  defaultSortColumn = ORDER_DEFAULT_NAME,
  defaultOrder = ORDER_DEFAULT_SORT,
  onPageChange = () => {},
  ...rest
}) {
  const [pagination, setPagination] = usePagination();
  const [sorter, setSortParams] = useOrderBy();
  const total = data?.totalCount;
  const current = pagination[PAGINATION_PAGE_NUMBER];
  const pageSize = pagination[PAGINATION_PAGE_SIZE];

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    setExpandedRowKeys([]);
  }, [data]);

  useMount(() => {
    if (!sorter[ORDER_BY_NAME]) {
      setSortParams({
        [ORDER_BY_NAME]: defaultSortColumn,
        [ORDER_BY_SORT]: defaultOrder,
      });
    }
  });

  const paginationConfig = useMemo(
    () => ({
      total,
      current,
      pageSize,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ["20", "40", "50"],
      onChange: onPageChange,
      showTotal: (total, range) => (
        <div>
          Showing{" "}
          <span>
            {range[0]}-{range[1]}
          </span>{" "}
          of <span>{total}</span>
          {` Results`}
        </div>
      ),
    }),
    [total, pageSize, current, onPageChange]
  );

  const columnsAddSorted = useMemo(
    () =>
      R.map(
        R.ifElse(
          R.propEq("key", sorter[ORDER_BY_NAME]),
          R.assoc("sortOrder", sorter[ORDER_BY_SORT]),
          (item) => item
        )
      )(columns),
    [columns, sorter]
  );

  const onRow = useCallback(
    (record, rowIndex) => {
      return {
        onClick: (_) => onRowClick && onRowClick(record, rowIndex),
      };
    },
    [onRowClick]
  );

  const onChange = useCallback(
    (page, _, sorter, { action }) => {
      if (action === "paginate") {
        setPagination({
          [PAGINATION_PAGE_NUMBER]: page.current,
          [PAGINATION_PAGE_SIZE]: page.pageSize,
        });
      } else if (action === "sort") {
        setSortParams({
          [ORDER_BY_NAME]: sorter.order
            ? Array.isArray(sorter.field)
              ? sorter.field.join(".")
              : sorter.field
            : undefined,
          [ORDER_BY_SORT]: sorter.order,
        });
      }
    },
    [setPagination, setSortParams]
  );

  return (
    <Table
      pagination={paginationConfig}
      dataSource={data?.data}
      onRow={onRow}
      onChange={onChange}
      rowKey="id"
      columns={columnsAddSorted}
      rowClassName={(_, i) => (i % 2 === 0 ? "even" : "odd")}
      {...rest}
      expandable={{
        ...(rest?.expandable ?? {}),
        expandedRowKeys,
        onExpandedRowsChange: (keys) => setExpandedRowKeys(keys),
      }}
    />
  );
}

export default TableWithPaginationAndSorting;
