import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { Button, Layout, PageHeader } from "antd";
import { useToggle } from "ahooks";
import { DoubleLeftOutlined } from "@ant-design/icons";
import "./index.less";
import classNames from "classnames";
import FilterableSidebar from "./FilterableSidebar";
import { useFilters } from "@/utils";

export default function FilterableContent({
  formConfig,
  children,
  buttons = [],
  defaultFilters = [],
  filterName,
  isFiltersConfigurable = true,
}) {
  const { filters } = useFilters();

  const [filtersCollapsed, { toggle }] = useToggle(true);
  const togglePanel = useCallback(() => toggle(), [toggle]);

  const filterCount = useMemo(() => Object.keys(filters || {}).length, [filters]);

  return (
    <Layout
      className={classNames({
        "filterable-layout": true,
        "sidebar-collapsed": filtersCollapsed,
      })}
    >
      <Layout.Sider
        className="filterable-sidebar"
        collapsedWidth={0}
        trigger={null}
        zeroWidthTriggerStyle={{ display: "none" }}
        collapsible
        width={220}
        collapsed={filtersCollapsed}
      >
        {filtersCollapsed || (
          <FilterableSidebar
            defaultFilters={defaultFilters}
            availableFilters={formConfig}
            filterName={filterName}
            isFiltersConfigurable={isFiltersConfigurable}
          />
        )}
      </Layout.Sider>
      <Layout>
        <PageHeader className="menu-header" title="">
          <div className="buttons-wrapper">
            <Button onClick={togglePanel} icon={<DoubleLeftOutlined />} className="btn-filters">
              Filters {filterCount > 0 ? `(${filterCount})` : ""}
            </Button>
            {buttons}
          </div>
        </PageHeader>
        <Layout.Content>{children}</Layout.Content>
      </Layout>
    </Layout>
  );
}

FilterableContent.propTypes = {
  formConfig: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  buttons: PropTypes.array,
  defaultFilters: PropTypes.arrayOf(PropTypes.string),
};
