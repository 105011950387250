import { Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "../../../common/Icons";
import { Box } from "../../../common/Box/Box";
import { MappingList } from "@/components/common/MappingList";
import { dictionaryToOptions } from "@/utils";
import { Field } from "@/components/common/Form/Field";
import { useDictionaries } from "@/utils/useDictionaries";
import { OpenPlayLabelPicker } from "./OpenPlayLabelPicker";

export const LabelMappings = ({ disabled }) => {
  const { dictionaries } = useDictionaries();

  const labelcampCompanies = dictionaries?.labelcamp.companies ?? [];

  return (
    <Box readOnly={disabled}>
      <MappingList
        name="labelMappings"
        left={<OpenPlayLabelPicker />}
        right={
          <Field
            name="labelcampLabelId"
            rules={[{ required: true, message: "Please, select Labelcamp label" }]}
          >
            <Select
              placeholder="Labelcamp Label"
              options={labelcampCompanies.map((company) => ({
                label: company.name,
                options: dictionaryToOptions(company.labels),
              }))}
              suffixIcon={<Icon component={DropdownArrow} />}
            />
          </Field>
        }
      />
    </Box>
  );
};
