import { api } from "./core";
import { DICTIONARIES_URL, UPDATE_DICTIONARIES_URL } from "./urls";
import type { Dictionaries } from "@/contexts/dictionaries/types";

export async function getDictionaries() {
  return api.get<any, Dictionaries>(DICTIONARIES_URL);
}

export async function updateDictionaries() {
  return api.post<any, Dictionaries>(UPDATE_DICTIONARIES_URL);
}
