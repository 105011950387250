import { useRequest } from "ahooks";
import { deleteFlowEvent, pullChangeEvents } from "@/api/flows";
import { Button, PageHeader, Popconfirm, Space, Table, Tooltip } from "antd";
import { ImportPlatformEventButton } from "./ImportPlatformEventButton";
import type { FlowEvent, ImportPlatformEventParams } from "@/api/flows/types";
import { FlowEventType } from "@/api/flows/types";
import type { ColumnsType } from "antd/lib/table/interface";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Refresh, Remove } from "@/components/common/Icons";
import { useCallback, useMemo } from "react";
import { useFlowEventImport, useFlowEvents } from "@/api/flows/hooks";

const channelLabelLookup: Record<FlowEventType, string> = {
  [FlowEventType.ChangeEvent]: "Change Event",
  [FlowEventType.PlatformEvent]: "Platform Event",
};

export const FlowEventList = () => {
  const { data: events, isLoading: fetchingList, mutate: refresh } = useFlowEvents();
  const { isMutating: refreshing, trigger: refreshPlatformEvent } = useFlowEventImport();

  const { loading: pullingChangeEvents, run: startPullingChangeEvents } = useRequest(pullChangeEvents, {
    manual: true,
  });

  const { loading: deleting, run: startDeleting } = useRequest(deleteFlowEvent, {
    manual: true,
  });

  const loading = fetchingList || pullingChangeEvents || deleting || refreshing;

  const handlePullEvents = async () => {
    await startPullingChangeEvents();
    await refresh();
  };

  const handleRemove = useCallback(
    async (name: string) => {
      await startDeleting(name);
      await refresh();
    },
    [refresh, startDeleting]
  );

  const handleRefresh = useCallback(
    async (params: ImportPlatformEventParams) => {
      await refreshPlatformEvent(params);
    },
    [refreshPlatformEvent]
  );

  const columns: ColumnsType<FlowEvent> = useMemo(
    () => [
      {
        title: "Label",
        dataIndex: "label",
        render: (_, entry) => <Tooltip title={entry.name}>{entry.label}</Tooltip>,
      },
      {
        title: "Entity",
        dataIndex: "entity",
      },
      {
        title: "Type",
        dataIndex: "type",
        render: (channel) => channelLabelLookup[channel] ?? "-",
      },
      {
        title: "Actions",
        render: (_, { name, label, type }) => (
          <Space>
            <IconButton
              disabled={type === FlowEventType.ChangeEvent}
              icon={Refresh}
              onClick={() => handleRefresh({ name, refresh: true })}
            />
            <Popconfirm
              title={`Are you sure you want to remove '${label}' event?`}
              onConfirm={() => handleRemove(name)}
              overlayStyle={{ maxWidth: 350 }}
            >
              <IconButton icon={Remove} />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [handleRemove, handleRefresh]
  );

  return (
    <>
      <PageHeader
        style={{ marginBottom: 16 }}
        title={<h1 style={{ margin: 0 }}>Flow Events</h1>}
        extra={[
          <Button key="cdc" type="primary" onClick={handlePullEvents} loading={pullingChangeEvents}>
            Import Change Events
          </Button>,
          <ImportPlatformEventButton key="platform" />,
        ]}
      />
      <Table dataSource={events} loading={loading} columns={columns} />
    </>
  );
};
