import { notification } from "antd";
export const openFormNotification = (name) =>
  openNotification({
    description: `Changes for ${name} are saved successfully`,
  });

export const openNotification = ({
  message = "Notification",
  description = "",
  type = "success",
} = {}) => {
  const args = {
    description,
    message,
    type,
    duration: 5,
    placement: "bottomRight",
  };
  notification.open(args);
};
