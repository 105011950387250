import * as R from "ramda";
import { Form, Input, PageHeader, Space, Tabs } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { FeedTab, Operation, Status, useIsAllowedForRoles, UserRole } from "@/utils";
import { OpenplayObjectType } from "@/types/common";
import { useHistory, useParams } from "react-router-dom";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import StorageForm from "./StorageForm/StorageForm";
import MappingsForm from "./MappingsForm/MappingsForm";
import FeedSummaryForm from "./FeedSummaryForm";
import { useBoolean } from "ahooks";
import { OperationTag } from "../../common/OperationTag/OperationTag";
import { FeedActions } from "./FeedActions";
import { AssetNamingsForm } from "./AssetNamingsForm/AssetNamingsForm";
import { FiltersForm } from "./FiltersForm/FiltersForm";
import { FormActions } from "../../common/FormActions";
import { TabErrorIcon } from "../../common/TabErrorIcon/TabErrorIcon";
import { FeedTypeSelector } from "./FeedTypeSelector";
import { TargetObjectTag } from "../../common/TargetObjectTag/TargetObjectTag";
import { ExportSchedulesList } from "@/components/Feeds/Details/ExportSchedulesForm/ExportSchedulesList";
import { parseFeedFields, prepareFeedDto } from "@/components/Feeds/Details/utils";
import { useFeedMutation } from "@/api/feeds/hooks";

const fieldsByTab = {
  summary: [
    "name",
    "deactivationReason",
    "endDate",
    "tenantCode",
    "description",
    "status",
    "assetsFormat",
    "deliveryType",
    "startDate",
  ],
  storage: ["accessKeyId", "secretAccessKey", "ip", "port", "userName", "password", "path", "batchSize"],
  mapping: ["template", "source", "target", "inputFormat", "outputFormat", "data"],
};

export function FeedForm({ initialValues = {}, isEditingByDefault = false }) {
  const [form] = Form.useForm();

  const status = Form.useWatch("status", form);
  const operation = Form.useWatch("operation", form);
  const targetObject = Form.useWatch("targetObject", form);

  const isFeedTypeSelected = !!operation && !!targetObject;

  const handleFeedTypeSelect = (values) => {
    form.setFieldsValue(values);
  };

  const couldDeleteFeed = useIsAllowedForRoles(UserRole.Admin);
  const { partnerId, activeTab = FeedTab.Summary } = useParams();
  const { allowNavigation, preventNavigation } = useNavigationConfirm();
  const history = useHistory();
  const [isEditing, { setFalse: disableEditing, setTrue: enableEditing }] = useBoolean(isEditingByDefault);

  const [fieldsWithErrors, setFieldsWithErrors] = useState([]);

  const isSummaryTabError = R.intersection(fieldsByTab.summary, fieldsWithErrors).length > 0;
  const isMappingTabError = R.intersection(fieldsByTab.mapping, fieldsWithErrors).length > 0;
  const isStorageTabError = R.intersection(fieldsByTab.storage, fieldsWithErrors).length > 0;
  const isAssetNamingTabError = fieldsWithErrors.includes("assetNamings");
  const isExportSchedulesTabError = fieldsWithErrors.includes("exportSchedules");

  const titleText = useMemo(() => (initialValues ? initialValues?.name || "New Feed" : ""), [initialValues]);

  const onTabChanged = useCallback(
    (newTab) => history.replace(`/partners/${partnerId}/feeds/${initialValues?.id || "new"}/${newTab}`),
    [initialValues, partnerId, history]
  );

  const handleErrors = useCallback(({ errorFields }) => {
    const names = R.compose(
      R.flatten,
      R.map((error) => error.name)
    )(errorFields);
    setFieldsWithErrors(names);
  }, []);

  useEffect(() => {
    if (history.location.state?.editMode) {
      enableEditing();
    }
  }, [history.location.state?.editMode, enableEditing]);

  const { trigger: saveChanges, isMutating: saving } = useFeedMutation(initialValues?.id);

  const handleValueChange = useCallback(
    (values) => {
      if (isEditing) {
        preventNavigation();
      }
      if (values.startDate !== undefined) {
        const endDate = form.getFieldValue("endDate");
        if (endDate && values.startDate && endDate < values.startDate) {
          form.setFieldsValue({ endDate: null });
        }
      }
    },
    [isEditing, form, preventNavigation]
  );

  const handleSave = useCallback(
    async (values) => {
      const dto = prepareFeedDto(values);

      try {
        const { id } = await saveChanges(dto);
        allowNavigation();
        if (!initialValues.id) {
          history.push(`/partners/${partnerId}/feeds/${id}/${FeedTab.Summary}`);
          return;
        }
        disableEditing();
      } catch (e) {
        console.error("unhandled error", e);
      }
    },
    [saveChanges, initialValues.id, allowNavigation, disableEditing, history, partnerId]
  );

  const handleCancel = useCallback(() => {
    allowNavigation();
    if (initialValues.id) {
      form.setFieldsValue(parseFeedFields(initialValues, operation, partnerId));
      disableEditing();
    } else {
      history.push(`/partners/${partnerId}/feeds`);
    }
  }, [allowNavigation, initialValues, form, operation, partnerId, disableEditing, history]);

  const isDisabledManualExport = status === Status.Draft;

  return (
    <>
      <PageHeader
        title={
          <Space align="center" size={12}>
            <span>{titleText}</span>
            {targetObject && <TargetObjectTag targetObject={targetObject} />}
            {operation && <OperationTag operation={operation} showLabel />}
          </Space>
        }
        extra={
          <>
            {!isEditing && (
              <FeedActions
                feed={initialValues}
                onDeleted={() => history.push(`/partners/${partnerId}/feeds`)}
                isManualExportEnabled={!isDisabledManualExport}
                showDeleteButton={couldDeleteFeed}
              />
            )}
            <FormActions
              isEditing={isEditing}
              onEdit={enableEditing}
              isSaving={saving}
              onSave={form.submit}
              onCancel={handleCancel}
              canSave={isFeedTypeSelected}
            />
          </>
        }
      />
      <Form
        form={form}
        onFinish={handleSave}
        onFinishFailed={handleErrors}
        onValuesChange={handleValueChange}
        initialValues={parseFeedFields(initialValues, operation, partnerId)}
        colon={false}
        labelAlign="left"
        wrapperCol={{ span: 15, offset: 1 }}
        labelCol={{ span: 8 }}
        validateMessages={{
          required: "Field is mandatory",
        }}
      >
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="operation" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="targetObject" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="partnerId" noStyle>
          <Input type="hidden" />
        </Form.Item>
        {isFeedTypeSelected ? (
          <Tabs activeKey={activeTab} onChange={onTabChanged}>
            <Tabs.TabPane
              forceRender
              key={FeedTab.Summary}
              tab={
                <>
                  <span>Summary</span>
                  {isSummaryTabError && <TabErrorIcon />}
                </>
              }
            >
              <FeedSummaryForm disabled={!isEditing} />
            </Tabs.TabPane>
            <Tabs.TabPane
              forceRender
              key={FeedTab.Mapping}
              tab={
                <>
                  <span>Mapping</span>
                  {isMappingTabError && <TabErrorIcon />}
                </>
              }
            >
              <MappingsForm disabled={!isEditing} />
            </Tabs.TabPane>
            {operation === Operation.Import && targetObject === OpenplayObjectType.Release && (
              <Tabs.TabPane
                forceRender
                key={FeedTab.AssetNaming}
                tab={
                  <>
                    <span>Asset Namings</span>
                    {isAssetNamingTabError && <TabErrorIcon />}
                  </>
                }
              >
                <AssetNamingsForm disabled={!isEditing} />
              </Tabs.TabPane>
            )}
            {operation === Operation.Export && targetObject === OpenplayObjectType.Release && (
              <Tabs.TabPane forceRender key={FeedTab.Filters} tab={<span>Filters</span>}>
                <FiltersForm disabled={!isEditing} />
              </Tabs.TabPane>
            )}
            {operation === Operation.Export && (
              <Tabs.TabPane
                forceRender
                key={FeedTab.Destination}
                tab={
                  <>
                    <span>Destination</span>
                    {isStorageTabError && <TabErrorIcon />}
                  </>
                }
              >
                <StorageForm
                  fieldName="storage"
                  operation={Operation.Export}
                  disabled={!isEditing}
                  targetObject={targetObject}
                />
              </Tabs.TabPane>
            )}
            {operation === Operation.Import && targetObject === OpenplayObjectType.Release && (
              <Tabs.TabPane
                forceRender
                key={FeedTab.Source}
                tab={
                  <>
                    <span>Source</span>
                    {isStorageTabError && <TabErrorIcon />}
                  </>
                }
              >
                <StorageForm
                  fieldName="storage"
                  operation={Operation.Import}
                  disabled={!isEditing}
                  targetObject={OpenplayObjectType.Release}
                />
              </Tabs.TabPane>
            )}
            {operation === Operation.Export && (
              <Tabs.TabPane
                forceRender
                key={FeedTab.ExportSchedules}
                tab={
                  <>
                    <span>Export Schedules</span>
                    {isExportSchedulesTabError && <TabErrorIcon />}
                  </>
                }
              >
                <ExportSchedulesList disabled={!isEditing} />
              </Tabs.TabPane>
            )}
          </Tabs>
        ) : (
          <FeedTypeSelector onSelect={handleFeedTypeSelect} />
        )}
      </Form>
    </>
  );
}

FeedForm.propTypes = {
  initialValues: PropTypes.object,
  isEditingByDefault: PropTypes.bool,
};
