import { api } from "../core";
import type { PaginatedResponse } from "../types";
import type { CreateEmailAlertDto, EmailAlert, EmailAlertFilters, UpdateEmailAlertDto } from "./types";

const url = "/api/email-alerts";

export const fetchEmailAlerts = (params: EmailAlertFilters) =>
  api.get<any, PaginatedResponse<EmailAlert>>(url, { params });

export const fetchEmailAlert = (id: string) => api.get<any, EmailAlert>(`${url}/${id}`);

export const createEmailAlert = (fields: CreateEmailAlertDto) => api.post<any, EmailAlert>(url, fields);

export const updateEmailAlert = (fields: UpdateEmailAlertDto) => api.put<any, EmailAlert>(url, fields);

export const deleteEmailAlert = (id: EmailAlert["id"]) => api.delete<any, void>(`${url}/${id}`);

export const saveEmailAlert = (fields: CreateEmailAlertDto | UpdateEmailAlertDto) =>
  "id" in fields ? updateEmailAlert(fields) : createEmailAlert(fields);

export const findEmailAlertByName = (name: string) =>
  api.get<any, EmailAlert>(`${url}/find`, { params: { name } });

export const duplicateEmailAlert = (id: string, name: string) =>
  api.post<void, EmailAlert>(`${url}/${id}/duplicate`, { name });
