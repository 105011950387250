import React, { useState } from "react";
import { AuthContext } from "@/contexts/auth";
import { DictionariesContext } from "@/contexts/dictionaries";
import { getCurrentUser } from "@/api/users";
import { Spin } from "antd";
import { useMount, useRequest, useToggle } from "ahooks";
import { getDictionaries } from "@/api/dictionaries";

export default function AuthStore({ children }) {
  const [user, setUser] = useState(null);
  const [dictionaries, setDictionaries] = useState({});
  const [loading, { toggle: toggleLoading }] = useToggle(true);

  const { run: loadCurrentUser, refresh } = useRequest(getCurrentUser, {
    onSuccess: (userDTO) => setUser(userDTO),
    onError: (_) => toggleLoading(false),
    manual: true,
  });

  const { run: loadDictionaries } = useRequest(getDictionaries, {
    onSuccess: (resData) => setDictionaries(resData.data),
    onError: (_) => toggleLoading(false),
    manual: true,
  });

  useMount(async () => {
    try {
      await loadCurrentUser();
      await loadDictionaries();
      toggleLoading(false);
    } catch (err) {
      //TODO think about correct handling rejection
      console.error(err);
    }
  });

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        refresh,
        loadDictionaries,
      }}
    >
      <DictionariesContext.Provider value={{ dictionaries, setDictionaries }}>
        {loading ? <Spin spinning={loading} /> : children}
      </DictionariesContext.Provider>
    </AuthContext.Provider>
  );
}
