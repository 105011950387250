import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { Alert, Button, Col, Form, Row } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { OpenplayQueryListItem } from "./OpenplayQueryListItem";
import "./OpenplayQueryList.less";
import { FullWidthButton } from "@/components/common/FullWidthButton/FullWidthButton";
import { Box } from "@/components/common/Box/Box";
import type { SpreadsheetTab } from "@/api/spreadsheets/types";
import type { ReportAction, ReportOpenplayQuery } from "@/api/reports/types";
import { v4 as uuidv4 } from "uuid";

type Props = {
  readOnly?: boolean;
};

const useNotRemovableKeys = () => {
  const tabs = Form.useWatch<SpreadsheetTab[]>(["spreadsheet", "tabs"]) ?? [];
  const queries = Form.useWatch<ReportOpenplayQuery[]>(["openplayQueries"]) ?? [];
  const actions = Form.useWatch<{
    pre: ReportAction[];
    post: ReportAction[];
  }>(["actions"]);

  return queries
    .filter((query) => {
      const usedInTabs = tabs.some((tab) => tab.openplayQueryId === query?.id);
      const usedInPreActions = actions?.pre?.some((action) => action.queryId === query?.id);
      const usedInPostActions = actions?.post?.some((action) => action.queryId === query?.id);
      return usedInTabs || usedInPreActions || usedInPostActions;
    })
    .map((query) => queries.indexOf(query));
};

const getEmptyValue = () => ({ id: uuidv4(), openplayQuery: { id: void 0 } });

export const OpenplayQueryList = ({ readOnly }: Props) => {
  const notRemovableKeys = useNotRemovableKeys();

  return (
    <Row gutter={[16, 16]}>
      {notRemovableKeys.length > 0 && (
        <Col span={24}>
          <Alert message="Queries that are used in spreadsheet tabs or actions cannot be removed" />
        </Col>
      )}
      <Col span={24}>
        <FieldList name="openplayQueries">
          {(fields, { add, remove }, { errors }) =>
            fields.length > 0 ? (
              <Box readOnly={readOnly}>
                <div className="openplay-query-list">
                  <Button
                    className="openplay-query-list__add"
                    size="small"
                    icon={<Icon component={Plus} />}
                    onClick={() => add(getEmptyValue())}
                  >
                    Add Query
                  </Button>
                  {fields.map((field) => (
                    <FieldListItem key={field.key} name={field.name}>
                      <OpenplayQueryListItem
                        fieldIndex={field.name}
                        onRemove={() => remove(field.name)}
                        removable={!notRemovableKeys.includes(field.name)}
                      />
                    </FieldListItem>
                  ))}
                  {errors.length > 0 && <Form.ErrorList errors={errors} />}
                </div>
              </Box>
            ) : (
              <FullWidthButton label="Add Openplay Query" icon={Plus} onClick={() => add(getEmptyValue())} />
            )
          }
        </FieldList>
      </Col>
    </Row>
  );
};
