import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { Select } from "antd";
import { usePermissionSubjects } from "@/api/users/hooks";
import type { PermissionAction } from "@/api/users/types";
import { getEmptyArray } from "@/utils/empty";

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  action?: PermissionAction;
};

export const PermissionSubjectPicker = ({ value, onChange, action }: Props) => {
  const { data: entries = getEmptyArray(), isLoading } = usePermissionSubjects();
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select subject"
      options={entries.map((entry) => ({
        value: entry.name,
        label: entry.label,
        disabled: action && entry.availableActions ? !entry.availableActions.includes(action) : false,
      }))}
      suffixIcon={<Icon component={DropdownArrow} />}
      disabled={!action || isLoading}
    />
  );
};
