import type { SelectProps } from "antd";
import { Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { useSpreadsheetColumnSets } from "@/api/spreadsheets/hooks";
import type { SpreadsheetColumnSet, SpreadsheetColumnSetFilters } from "@/api/spreadsheets/types";

type Props = Omit<SelectProps<string, SpreadsheetColumnSet>, "options"> & {
  filters: SpreadsheetColumnSetFilters;
};

export const ColumnSetPicker = ({ filters, ...props }: Props) => {
  const { data } = useSpreadsheetColumnSets(filters);
  return (
    <Select
      style={{ minWidth: 200 }}
      placeholder="Select Column Set"
      {...props}
      options={data.data}
      suffixIcon={<Icon component={DropdownArrow} />}
      fieldNames={{ label: "name", value: "id" }}
    />
  );
};
