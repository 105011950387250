import type {
  CreateSpreadsheetColumnSetDto,
  SpreadsheetColumnSet,
  UpdateSpreadsheetColumnSetDto,
} from "@/api/spreadsheets/types";
import { Alert, Button, Col, Divider, Form, Input, PageHeader, Popconfirm, Row, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import { useBoolean } from "ahooks";
import { Summary } from "@/components/common/Summary/Summary";
import { FormActions } from "@/components/common/FormActions";
import { useSpreadsheetColumnSetMutation } from "@/api/spreadsheets/hooks";
import { deleteColumnSet, findColumnSetByName } from "@/api/spreadsheets";
import { HiddenField } from "@/components/common/HiddenField";
import { SpreadsheetColumnList } from "app/components/Spreadsheets/SpreadsheetColumnList";
import { Box } from "@/components/common/Box/Box";
import { ColumnSetTypeSelector } from "@/components/Spreadsheets/ColumnSets/Details/ColumnSetTypeSelector";
import { isUniqueName } from "@/utils/validation";
import { Field } from "@/components/common/Form/Field";
import { getTabSourceLabel } from "@/utils";

type Props = {
  initialValues?: Partial<SpreadsheetColumnSet & { tabs: any[] }>;
  isEditingByDefault?: boolean;
};

type FormValues = CreateSpreadsheetColumnSetDto | UpdateSpreadsheetColumnSetDto;

export const ColumnSetForm = ({ initialValues = {}, isEditingByDefault = false }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const history = useHistory();
  const { allowNavigation, preventNavigation } = useNavigationConfirm();

  const [editing, { setTrue: enableEditing, setFalse: disableEditing }] = useBoolean(isEditingByDefault);

  const sourceObject = Form.useWatch(["sourceObject", "name"], form);
  const source = Form.useWatch(["source"], form);

  const isTypeSelected = !!source;

  const title = initialValues.name ?? "New Spreadsheet Column Set";
  const hasTabs = initialValues.tabs ? initialValues.tabs.length > 0 : false;
  const isDeletable = !!initialValues.id && !editing && !hasTabs;

  const { trigger: save, isMutating: saving } = useSpreadsheetColumnSetMutation(initialValues.id);

  const handleSave = async ({ sourceObject, ...rest }: FormValues) => {
    try {
      const { id } = await save({
        sourceObject: sourceObject?.name ? sourceObject : null,
        ...rest,
      });
      allowNavigation();
      if (!initialValues.id) {
        history.push(`/column-sets/${id}`);
        return;
      }
      disableEditing();
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  const handleFinish = async (values: FormValues) => {
    await handleSave(values);
  };

  const handleDelete = async () => {
    if (initialValues.id) {
      await deleteColumnSet(initialValues.id);
      history.push(`/column-sets`);
    }
  };

  const handleCancel = () => {
    allowNavigation();
    if (initialValues.id) {
      form.setFieldsValue(initialValues);
      disableEditing();
    } else {
      history.push(`/column-sets`);
    }
  };

  const handleValueChange = () => {
    if (editing) {
      preventNavigation();
    }
  };

  const handleEdit = () => {
    enableEditing();
  };

  const handleTypeSelect = ({ sourceObject, source }) => {
    if (sourceObject) {
      form.setFieldValue(["sourceObject", "name"], sourceObject);
    }
    form.setFieldValue("source", source);
  };

  return (
    <>
      <PageHeader
        title={<h1 style={{ margin: 0 }}>{title}</h1>}
        extra={
          <>
            {source && (
              <div style={{ marginRight: 16 }}>
                <Summary items={[{ label: "Source", value: getTabSourceLabel(source) }]} />
              </div>
            )}
            {sourceObject && (
              <div style={{ marginRight: 16 }}>
                <Summary
                  items={[
                    { label: "Source Object", value: initialValues.sourceObject?.label ?? sourceObject },
                  ]}
                />
              </div>
            )}
            <FormActions
              isEditing={editing}
              onSave={form.submit}
              isSaving={saving}
              onEdit={handleEdit}
              onCancel={handleCancel}
              canSave={isTypeSelected}
            />
            {isDeletable && (
              <Popconfirm
                title="Are you sure to delete the column set?"
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button>Delete</Button>
              </Popconfirm>
            )}
            {hasTabs && !editing && (
              <Tooltip title="This column set is currently in use by one or more reports or email alerts and cannot be deleted">
                <Button disabled>Delete</Button>
              </Tooltip>
            )}
          </>
        }
      />
      <Form
        form={form}
        onFinish={handleFinish}
        onValuesChange={handleValueChange}
        validateMessages={{ required: "Field is mandatory" }}
        labelAlign="left"
        initialValues={initialValues}
      >
        <HiddenField name="id" />
        <HiddenField name={["sourceObject", "name"]} />
        <HiddenField name="source" />
        {isTypeSelected ? (
          <Box readOnly={!editing}>
            <Row gutter={[16, 16]}>
              {hasTabs && (
                <Col span={24}>
                  <Alert
                    type="warning"
                    message="The column set is currently in use by one or more entities. Editing it will affect all reports or alerts that reference it."
                  />
                </Col>
              )}
              <Col span={12}>
                <Field
                  name="name"
                  label="Name"
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    { required: true },
                    isUniqueName(
                      findColumnSetByName,
                      "A column with entered name already exists in the system"
                    ),
                  ]}
                >
                  <Input placeholder="Name" />
                </Field>
              </Col>
            </Row>
            <Divider orientation="left">Columns</Divider>
            <SpreadsheetColumnList sourceObject={sourceObject} source={source} />
          </Box>
        ) : (
          <ColumnSetTypeSelector onSelect={handleTypeSelect} />
        )}
      </Form>
    </>
  );
};
