import { Divider, Form, Input, PageHeader, Typography } from "antd";
import { Summary } from "../../common/Summary/Summary";
import { useBoolean } from "ahooks";
import { useCallback } from "react";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import { openFormNotification } from "../../common/CommonNotification";
import { FormActions } from "../../common/FormActions";
import { Field } from "@/components/common/Form/Field";
import { HiddenField } from "@/components/common/HiddenField";
import { Box } from "@/components/common/Box/Box";
import type { Script } from "@/api/flows/types";
import { useScriptMutation } from "@/api/flows/hooks";
import { Markdown } from "@/components/Markdown";

const { TextArea } = Input;

type Props = {
  initialValues: Script;
};

export const ScriptForm = ({ initialValues }: Props) => {
  const [form] = Form.useForm();

  const [isEditing, { setFalse: disableEditing, setTrue: enableEditing }] = useBoolean();
  const { allowNavigation, preventNavigation } = useNavigationConfirm();

  const { isMutating: saving, trigger: updateScript } = useScriptMutation(initialValues.key);

  const handleSave = async (values: typeof initialValues) => {
    const dto = { ...values };

    try {
      await updateScript({
        key: initialValues.key,
        description: dto.description,
      });
      openFormNotification("the script");
      allowNavigation();
      disableEditing();
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  const handleCancel = useCallback(() => {
    allowNavigation();
    form.resetFields();
    disableEditing();
  }, [form, disableEditing, allowNavigation]);

  const handleValueChange = useCallback(() => {
    if (isEditing) {
      preventNavigation();
    }
  }, [isEditing, preventNavigation]);

  return (
    <>
      <PageHeader
        title={<h1>{initialValues.label}</h1>}
        extra={
          <>
            <div style={{ marginRight: 16 }}>
              <Summary items={[{ label: "Object", value: initialValues.sourceObject }]} />
            </div>
            <FormActions
              isEditing={isEditing}
              onEdit={enableEditing}
              isSaving={saving}
              onSave={form.submit}
              onCancel={handleCancel}
            />
          </>
        }
      />
      <Form
        form={form}
        onFinish={handleSave}
        initialValues={initialValues}
        colon={false}
        labelAlign="left"
        wrapperCol={{ span: 15, offset: 1 }}
        labelCol={{ span: 8 }}
        onValuesChange={handleValueChange}
        validateMessages={{
          required: "Field is mandatory",
        }}
      >
        <HiddenField name="key" />
        <Box readOnly={!isEditing}>
          <Field name="description" label="Description" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <TextArea placeholder="Description" rows={3} />
          </Field>
        </Box>
        {initialValues.details && (
          <>
            <Divider orientation="left">Details</Divider>
            <Typography>
              <Markdown markdown={initialValues.details} />
            </Typography>
          </>
        )}
      </Form>
    </>
  );
};
